import { inject } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { initEffectActions } from '@scheduler-frontend/data-access-initialize';
import { slotsActions, SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, Observable, of, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';
import { SearchSlotApi } from '../api/search-slot.api';
import { SearchSelectors } from '../selector/search.selectors';

export class LoadingSlotsBasedOnSystemSearchEffect implements OnRunEffects {
  private readonly actions = inject(Actions);

  private readonly searchSlotApi = inject(SearchSlotApi);

  private readonly store = inject(Store);

  public readonly loadSlotsOfCurrentSystemSearch = createEffect(() =>
    this.actions.pipe(
      ofType(
        searchActions.initLoadingSlotsBasedOnSystemSearch,
        searchActions.setCurrentSystemSearchSuccess,
        searchActions.reloadCurrentSearch,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearchId), isDefined()),
      ]),
      switchMap(([_, search]) => {
        return this.searchSlotApi.execute(search, { withRelatedShifts: true, page: 1 }).pipe(
          map((result) =>
            slotsActions.loadSlotsSuccess({
              slots: result['hydra:member'],
              totalItems: result['hydra:totalItems'],
              chunk: 1,
            }),
          ),
          catchError((error) => of(slotsActions.loadSlotsFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly nextChunkForCurrentSearch = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.nextChunkCurrentSearch),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearch), isDefined()),
        this.store.pipe(
          select(SlotsSelectors.selectCurrentSearchLoadedChunks),
          isDefined(),
          map((chunks) => Math.max(...chunks)),
        ),
      ]),
      switchMap(([_, search, lastChunk]) =>
        this.searchSlotApi.execute(search.hash, { withRelatedShifts: true, page: lastChunk }).pipe(
          map((result) =>
            slotsActions.loadSlotsSuccess({
              slots: result['hydra:member'],
              totalItems: result['hydra:totalItems'],
              chunk: lastChunk + 1,
            }),
          ),
          catchError((error) => of(slotsActions.loadSlotsFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly loadCurrentSearchFailure = createEffect(
    () =>
      this.actions.pipe(
        handleEndpointFailure(slotsActions.loadSlotsFailure, {
          message: 'Er is iets misgegaan bij het laden van de zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setCurrentSystemSearchFailure = createEffect(
    () =>
      this.actions.pipe(
        handleEndpointFailure(searchActions.addSlotListToCurrentSearchFailure, {
          message: 'Er is iets misgegaan bij het laden van de gegeven zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions.pipe(
      ofType(initEffectActions.startLoadingSlotsBasedOnSystemSearch),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(
            this.actions.pipe(ofType(initEffectActions.stopLoadingSlotsBasedOnSystemSearch)),
          ),
        ),
      ),
    );
  }
}
