import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCopy, faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  ModalController,
  ViewDidEnter,
} from '@ionic/angular/standalone';
import { LessonDetailedContract } from '@scheduler-frontend/assignment-contracts';
import {
  BusinessServiceContract,
  DeliveryTypeContract,
  DeliveryTypeDisplayValues,
  DeliveryTypeEnum,
} from '@scheduler-frontend/data-access-business-services';
import { LocationContract, LocationsStoreService } from '@scheduler-frontend/data-access-locations';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { JsonLdInterface } from '@techniek-team/api-platform';
import { HighlightSearchPipe } from '@techniek-team/common';
import {
  TtIonSelectSearchControlComponent,
  TtIonSelectSearchOptionDirective,
} from '@techniek-team/components/ion-select-search';
import {
  TtModalComponent,
  TtModalTitleComponent,
  TtSimpleModalButton,
  TtSimpleModalComponent,
} from '@techniek-team/components/modal';
import { IonColorType } from '@techniek-team/lyceo-style';
import { isDefined } from '@techniek-team/rxjs';
import { format, formatISO, isDate, isPast } from 'date-fns';
import { NgxControlError } from 'ngxtension/control-error';
import { distinctUntilChanged, map } from 'rxjs';
import { CreateSlotSetStoreService } from '../create-slot-set-store.service';
import { CreateSlotsRequirementsStoreService } from '../create-slots-requirements-store.service';
import { CreateSlotsStoreService } from '../create-slots-store.service';
import { DatesControlComponent } from './dates-control/dates-control.component';
import { FineTuneComponent } from './fine-tune/fine-tune.component';
import { WhoControlComponent, WhoFormData } from './who-control/who-control.component';

export enum CreateSlotSegments {
  SHIFT_TEMPLATE = 'SHIFT_TEMPLATE',
  SHIFT_FINE_TUNE = 'SHIFT_FINE_TUNE',
}

@Component({
  selector: 'app-create-slot-modal',
  templateUrl: './create-slot-modal.component.html',
  styleUrl: './create-slot-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FaIconComponent,
    TtModalComponent,
    HighlightSearchPipe,
    TtIonSelectSearchControlComponent,
    DatePipe,
    NgxControlError,
    DatesControlComponent,
    ReactiveFormsModule,
    WhoControlComponent,
    FineTuneComponent,
    IonItem,
    IonLabel,
    IonNote,
    IonSelect,
    IonSelectOption,
    IonText,
    IonButton,
    IonSpinner,
    TtModalTitleComponent,
    TtIonSelectSearchOptionDirective,
  ],
  host: {
    '(window:beforeunload)': 'unloadHandler($event)',
  },
})
export class CreateSlotModalComponent implements ViewDidEnter {
  protected readonly createSlotsStoreService = inject(CreateSlotsStoreService);

  protected readonly requirementsStoreService = inject(CreateSlotsRequirementsStoreService);

  protected readonly setStoreService = inject(CreateSlotSetStoreService);

  protected readonly locationsStoreService = inject(LocationsStoreService);

  protected readonly modalController = inject(ModalController);

  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  private readonly destroyRef = inject(DestroyRef);

  protected readonly faCopy = faCopy;

  protected readonly faTrash = faTrash;

  protected readonly CreateSlotSegments = CreateSlotSegments;

  protected readonly DeliveryTypeDisplayValues = DeliveryTypeDisplayValues;

  protected currentSegment = signal<string>(CreateSlotSegments.SHIFT_TEMPLATE);

  protected readonly slotTemplateForm = new FormGroup({
    productLocation: new FormControl<LocationContract | null>(null, [Validators.required]),
    businessService: new FormControl<BusinessServiceContract | null>(
      { value: null, disabled: true },
      [Validators.required],
    ),
    deliveryType: new FormControl<DeliveryTypeContract | null>({ value: null, disabled: true }),
    when: new FormControl<LessonDetailedContract[] | Date[] | null>(null, [Validators.required]),
    who: new FormArray([this.createWhoFormGroup()], [Validators.minLength(1)]),
    schedule: new FormControl<ScheduleContract | null>({ value: null, disabled: true }, [
      Validators.required,
    ]),
  });

  protected setFormValidityToStore = this.slotTemplateForm.valueChanges
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      map(() => this.slotTemplateForm.valid),
      distinctUntilChanged(),
    )
    .subscribe((value) => {
      this.setStoreService.changeFormValidity(value);
    });

  protected setProductLocationToStore = this.slotTemplateForm.controls.productLocation.valueChanges
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      isDefined(),
      distinctUntilChanged((a, b) => a['@id'] === b['@id']),
    )
    .subscribe((value) => {
      this.setStoreService.changeProductLocation(value);
    });

  protected setBusinessServiceToStore = this.slotTemplateForm.controls.businessService.valueChanges
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      isDefined(),
      distinctUntilChanged((a, b) => a['@id'] === b['@id']),
    )
    .subscribe((value) => {
      this.slotTemplateForm.controls.when.reset(null);
      this.setStoreService.changeBusinessService(value);
    });

  protected setDeliveryTypeToStore = this.slotTemplateForm.controls.deliveryType.valueChanges
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      isDefined(),
      distinctUntilChanged((a, b) => a?.['@id'] === b?.['@id']),
    )
    .subscribe((value) => {
      this.setStoreService.changeDeliveryType(value);
    });

  protected setScheduleToStore = this.slotTemplateForm.controls.schedule.valueChanges
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      isDefined(),
      distinctUntilChanged((a, b) => a?.['@id'] === b?.['@id']),
    )
    .subscribe((value) => {
      this.setStoreService.changeSchedule(value);
    });

  protected setWhenToStore = this.slotTemplateForm.controls.when.valueChanges
    .pipe(takeUntilDestroyed(this.destroyRef), isDefined())
    .subscribe((value) => {
      if (isDate(value[0])) {
        this.setStoreService.changeWhen(value.map((item) => formatISO(item as Date)));
        return;
      }
      this.setStoreService.changeWhen(value as LessonDetailedContract[]);
    });

  protected setActiveLocationAsProductLocation = effect(() => {
    const location = this.locationsStoreService.active();
    if (location && this.slotTemplateForm.controls.productLocation.untouched) {
      this.slotTemplateForm.controls.productLocation.reset(location);
      this.slotTemplateForm.markAsPristine();
    }
  });

  protected enableBusinessServiceControlOnLocationSelect = effect(() => {
    const list = this.createSlotsStoreService.businessServicesByLocation();
    if (list.length === 0) {
      this.slotTemplateForm.controls.businessService.reset({ value: null, disabled: true });
      return;
    }

    const currentSelected = this.slotTemplateForm.controls.businessService.value;
    const selectedItem = currentSelected
      ? (list.find((item) => item?.['@id'] === currentSelected['@id']) ?? null)
      : null;
    this.slotTemplateForm.controls.businessService.reset({ value: selectedItem, disabled: false });
  });

  protected enableDeliveryTypeOnBusinessServiceSelect = effect(() => {
    const list = this.createSlotsStoreService.deliveryTypes();
    if (!list || list.length === 0) {
      this.slotTemplateForm.controls.deliveryType.reset({ value: null, disabled: true });
      return;
    }
    if (list.length === 1) {
      this.slotTemplateForm.controls.deliveryType.reset({ value: list[0], disabled: false });
      return;
    }

    const atLocation = list.find((type) => {
      const match: boolean = type.deliveryType === DeliveryTypeEnum.ALL_AT_LOCATION;
      const inPast: boolean = isPast(type.validityRange.end);
      return match && !inPast;
    });

    this.slotTemplateForm.controls.deliveryType.reset({
      value: atLocation ?? list[0],
      disabled: false,
    });
  });

  protected resetScheduleOnChange = effect(
    () => {
      const list = this.createSlotsStoreService.foundSchedules();
      if (list.length === 0) {
        this.slotTemplateForm.controls.schedule.reset({ value: null, disabled: true });
        this.changeDetectorRef.markForCheck();
        return;
      }

      const currentSelected = this.slotTemplateForm.controls.schedule.value;
      const selectedItem = currentSelected
        ? (list.find((item) => item['@id'] === currentSelected['@id']) ?? null)
        : null;

      this.slotTemplateForm.controls.schedule.reset({
        value: selectedItem ? selectedItem : null,
        disabled: false,
      });
    },
    { allowSignalWrites: true },
  );

  protected disableScheduleOnLoadingSchedules = effect(
    () => {
      const loading = this.createSlotsStoreService.loadingSchedules();
      if (loading) {
        this.slotTemplateForm.controls.schedule.disable();
        this.changeDetectorRef.markForCheck();
        return;
      }
      this.slotTemplateForm.controls.schedule.enable();
      this.changeDetectorRef.markForCheck();
    },
    { allowSignalWrites: true },
  );

  protected disableLessonOnLoadingLessons = effect(
    () => {
      const loading = this.createSlotsStoreService.loadingLessons();
      if (loading) {
        this.slotTemplateForm.controls.when.disable();
        this.changeDetectorRef.markForCheck();
        return;
      }
      this.slotTemplateForm.controls.when.enable();
      this.changeDetectorRef.markForCheck();
    },
    { allowSignalWrites: true },
  );

  protected displayByName = (item: LocationContract | ScheduleContract) => item.name as string;

  protected displayBusinessService = (item: BusinessServiceContract): string => {
    return item.name + ' (' + item.businessEntity.name + ')';
  };

  protected displayLesson = (item: LessonDetailedContract): string => {
    return (
      (item.name ? item.name + ' - ' : '') +
      format(item.date, 'dd-MM-yyyy') +
      ' - (' +
      item.numberOfPupils +
      ' leerlingen)'
    );
  };

  public unloadHandler(event: Event): void {
    if (!this.slotTemplateForm.pristine || !this.slotTemplateForm.pristine) {
      event.preventDefault();
    }
  }

  public async ionViewDidEnter(): Promise<void> {
    const modal: HTMLIonModalElement | undefined = await this.modalController.getTop();
    if (!modal) {
      return;
    }

    modal.canDismiss = (): Promise<boolean> => this.canDismiss();
  }

  /**
   * This method show a confirmation message when the user wants to close the
   * window when there are unsaved changes.
   */
  private async canDismiss(): Promise<boolean> {
    if (!this.slotTemplateForm.pristine) {
      const buttons: TtSimpleModalButton[] = [
        { text: 'Nee', color: IonColorType.PRIMARY, fill: 'outline', role: 'cancel' },
        { text: 'Ja', color: IonColorType.PRIMARY, role: 'confirm' },
      ];
      const confirmModal: HTMLIonModalElement = await this.modalController.create({
        component: TtSimpleModalComponent,
        cssClass: 'stack-modal',
        componentProps: {
          title: 'Let op!',
          message: 'Je wijzigingen zijn nog niet opgeslagen. Weet je zeker dat je wilt annuleren?',
          buttons: buttons,
        },
      });
      confirmModal.present();

      const { role } = await confirmModal.onWillDismiss();

      if (role !== 'confirm') {
        return false;
      }

      this.slotTemplateForm.reset();
      return true;
    }

    return true;
  }

  protected removeWhoControl(index: number): void {
    this.slotTemplateForm.controls['who'].removeAt(index);
  }

  protected cloneWhoControl(index: number): void {
    this.slotTemplateForm.controls['who'].push(
      this.createWhoFormGroup(this.slotTemplateForm.controls['who'].at(index).value),
    );
  }

  protected addWhoControl(): void {
    this.slotTemplateForm.controls['who'].push(this.createWhoFormGroup());
  }

  protected compareById(first: JsonLdInterface, second: JsonLdInterface): boolean {
    return first?.['@id'] === second?.['@id'];
  }

  protected compareWithId(el: JsonLdInterface): string {
    return el['@id'];
  }

  private createWhoFormGroup(values?: WhoFormData | null) {
    return new FormControl<WhoFormData | null>(values ?? null);
  }
}
