import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { ApproachEventType } from '@scheduler-frontend/approach-contracts';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import {
  initEffectActions,
  initializeActions,
  SchedulingViewQueryParams,
} from '@scheduler-frontend/data-access-initialize';
import { slotsActions } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map } from 'rxjs';
import { schedulingApproachCandidatesActions } from '../actions/scheduling-approach-candidates.actions';
import { schedulingActions } from '../actions/scheduling.actions';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

@Injectable()
export class SchedulingEffects {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  private readonly router: Router = inject(Router);

  public updateUrlOnSlotSelection = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingActions.selectSlots,
        schedulingActions.deselectSlots,
        schedulingActions.deselectSlotsInAssignment,
        schedulingActions.clearSelectedSlots,
        schedulingActions.selectAllSlots,
        schedulingActions.selectSlotsInAssignment,
      ),
      concatLatestFrom(() => [this.store.select(SchedulingSelectors.selectedSlotIds)]),
      map(([_, slots]) => {
        return initializeActions.changeCurrentRouteQueryParams({
          key: SchedulingViewQueryParams.SELECTED_SLOTS,
          newValue: slots,
        });
      }),
    ),
  );

  public updateUrlOnCandidateSelection = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingActions.selectCandidates,
        schedulingActions.deselectCandidates,
        schedulingActions.clearSelectedCandidates,
      ),
      concatLatestFrom(() => [this.store.select(SchedulingSelectors.selectedCandidateIds)]),
      map(([_, selectedCandidates]) => {
        return initializeActions.changeCurrentRouteQueryParams({
          key: SchedulingViewQueryParams.SELECTED_CANDIDATES,
          newValue: selectedCandidates,
        });
      }),
    ),
  );

  public selectSlotsInAssignmentForRejection = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.selectSlotsInAssignmentForRejection),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.slotsInView).pipe(isDefined()),
      ]),
      map(([action, slotList]) => {
        const slotsToSelect: string[] = slotList
          .filter((slot: SlotContract | SlotDetailedContract) => {
            if (slot && 'assignmentHasSlot' in slot) {
              return (
                jsonLdSelectId(slot.assignmentHasSlot?.assignment?.['@id']) === action.assignment
              );
            }
            return false;
          })
          .map((slot: SlotContract | SlotDetailedContract) => jsonLdSelectId(slot));
        return schedulingActions.selectSlotsForRejection({ items: slotsToSelect });
      }),
    ),
  );

  public deselectSlotsInAssignmentForRejection = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.deselectSlotsInAssignmentForRejection),
      concatLatestFrom(() => [this.store.select(SchedulingSelectors.selectedSlotsForRejection)]),
      map(([action, selectedSlots]) => {
        const slotsToDeselect: string[] = selectedSlots
          .filter((slot: SlotContract | SlotDetailedContract) => {
            if (slot && 'assignmentHasSlot' in slot) {
              return (
                jsonLdSelectId(slot.assignmentHasSlot?.assignment?.['@id']) === action.assignment
              );
            }
            return false;
          })
          .map((slot: SlotContract | SlotDetailedContract) => jsonLdSelectId(slot));
        return schedulingActions.deselectSlotsForRejection({ items: slotsToDeselect });
      }),
    ),
  );

  public setSlotListOnLoadingSlotSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.loadSlotSuccess),
      filter((action) => action.setAsSlotList),
      map((action) =>
        schedulingActions.setSlotList({
          items: action.slots.map((slot) => jsonLdSelectId(slot['@id'])),
        }),
      ),
    ),
  );

  public clearSelectionOnStoppingSchedulingBySearch = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.stopScheduleManagement),
      map(() => schedulingActions.clearAllSelections()),
    ),
  );

  public clearSelectedCandidatesEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.clearAllSelections),
      map(schedulingActions.clearSelectedCandidates),
    ),
  );

  public clearSelectedSlotsMarkedForRejectionEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.clearAllSelections),
      map(schedulingActions.clearSelectedSlotsForRejection),
    ),
  );

  public redirectToPageOnApproachSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(schedulingApproachCandidatesActions.communicateSuccess),
        map((action) => {
          if (
            action.approach.latestCommunicationEvent?.types.includes(
              ApproachEventType.PHONE_ANSWERED,
            )
          ) {
            return this.router.navigateByUrl(
              `/benader-begeleider/${jsonLdSelectId(action.approach)}/inplannen`,
            );
          }
          return this.router.navigate(['benaderlijst']);
        }),
      ),
    { dispatch: false },
  );
}
