import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSearchContract } from '../../contract/user-search.contract';

@Injectable({
  providedIn: 'root',
})
export class SetFavoriteSearchApi {
  protected readonly http = inject(HttpClient);

  public execute(search: UserSearchContract): Observable<UserSearchContract> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search/${
      search.search.hash
    }/favorite/${search.isFavorite ? 0 : 1}`;

    return this.http.get<void>(url).pipe(
      map(() => ({
        ...search,
        isFavorite: !search.isFavorite,
      })),
    );
  }
}
