import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { initEffectActions, initializeActions } from '@scheduler-frontend/data-access-initialize';
import {
  schedulingActions,
  schedulingViewActions,
  SchedulingViewSelectors,
} from '@scheduler-frontend/data-access-scheduling';
import { slotsActions } from '@scheduler-frontend/data-access-slots';
import { objectToSearchExpression } from '@scheduler-frontend/search-expression';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { isEqual, omit } from 'lodash-es';
import { catchError, exhaustMap, filter, from, Observable, of, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSearchDetailedContract, SearchContract } from '../../contract/search.contract';
import { searchActions } from '../action/search.actions';
import { CreateSearchHashApi } from '../api/create-search-hash.api';
import { LoadSearchApi } from '../api/load-search.api';
import { MercureExportChangedEventsApi } from '../api/mercure-export-changed-events.api';
import { SearchSelectors } from '../selector/search.selectors';
import { adjustFiltersToDateRangeToView } from './functions/adjust-filters-to-date-range-to-view.function';
import { createHash } from './functions/create-hash.function';

@Injectable()
export class SearchEffects implements OnRunEffects {
  private readonly actions = inject(Actions);

  private readonly createSearchHashApi = inject(CreateSearchHashApi);

  private readonly loadSearchApi = inject(LoadSearchApi);

  private readonly mercureExportChangedEventsApi = inject(MercureExportChangedEventsApi);

  private readonly store = inject(Store);

  public readonly createSearchHash = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.createSearchHash),
      switchMap(({ filters, isUserInitiated, setAsCurrentSearch }) =>
        this.createSearchHashApi.execute(filters, isUserInitiated).pipe(
          map((response) =>
            searchActions.createSearchHashSuccess({
              isUserInitiated: isUserInitiated,
              search: response,
              setAsCurrentSearch: setAsCurrentSearch,
            }),
          ),
          catchError((error) => of(searchActions.createSearchHashFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly setCurrentSystemSearchOnlyWhenDifferent = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.setCurrentSystemSearch),
      concatLatestFrom(() => [this.store.pipe(select(SearchSelectors.currentSearch))]),
      map(([action, currentSearch]) => {
        if (action.searchId === currentSearch?.hash) {
          return initializeActions.void();
        }
        return searchActions.setCurrentSystemSearchSuccess(action);
      }),
    ),
  );

  public readonly appendToSearchHash = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.appendToSearchHash),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearch)),
        this.store.pipe(select(SearchSelectors.toBeAppendedToSearch)),
      ]),
      switchMap(
        ([
          { filters, removeKeys, isUserInitiated, setAsCurrentSearch },
          currentSearch,
          toBeAppendedToSearch,
        ]) => {
          const newFilters = omit(
            {
              ...(currentSearch?.metaData?.filters ?? {}),
              ...(toBeAppendedToSearch ?? {}),
              ...filters,
            },
            removeKeys ?? [],
          );
          if (currentSearch && isEqual(newFilters, currentSearch.metaData?.filters)) {
            return of(initializeActions.void());
          }
          return this.createSearchHashApi.execute(newFilters, isUserInitiated).pipe(
            map((response) =>
              searchActions.appendToSearchHashSuccess({
                isUserInitiated: isUserInitiated,
                search: response,
                setAsCurrentSearch: setAsCurrentSearch,
              }),
            ),
            catchError((error) => of(searchActions.createSearchHashFailure({ error: error }))),
          );
        },
      ),
    ),
  );

  public readonly removeFromSearchHash = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.removeFromSearchHash),
      concatLatestFrom(() => [this.store.pipe(select(SearchSelectors.currentSearch), isDefined())]),
      switchMap(([{ keys, isUserInitiated, setAsCurrentSearch }, currentSearch]) => {
        const newFilters = omit({ ...(currentSearch.metaData?.filters ?? {}) }, keys);
        if (currentSearch && isEqual(newFilters, currentSearch.metaData?.filters)) {
          return of(initializeActions.void());
        }
        return this.createSearchHashApi
          .execute(omit({ ...(currentSearch.metaData?.filters ?? {}) }, keys), isUserInitiated)
          .pipe(
            map((response) =>
              searchActions.removeFromSearchHashSuccess({
                isUserInitiated: isUserInitiated,
                search: response,
                setAsCurrentSearch: setAsCurrentSearch,
              }),
            ),
            catchError((error) => of(searchActions.createSearchHashFailure({ error: error }))),
          );
      }),
    ),
  );

  public readonly createSearchHashFailure = createEffect(
    () =>
      this.actions.pipe(
        handleEndpointFailure(searchActions.createSearchHashFailure, {
          message: 'Er is iets misgegaan bij het aanmaken van de zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public listenForExportChange = createEffect(() =>
    this.mercureExportChangedEventsApi
      .exportChangeEvents()
      .pipe(map(() => searchActions.reloadCurrentSearch())),
  );

  public readonly loadSearchSystemHash = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.startColdSystemSearch),
      switchMap((action) =>
        this.loadSearchApi.execute(action.searchId).pipe(
          map((response) => {
            return searchActions.startColdSystemSearchSuccess({
              search: response,
            });
          }),
          catchError((error) => of(searchActions.startColdSystemSearchFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly addSlotsToCurrentSearchState = createEffect(() =>
    this.actions.pipe(
      ofType(slotsActions.loadSlotsSuccess),
      concatLatestFrom(() => this.store.pipe(select(SearchSelectors.currentSearch), isDefined())),
      map(([action, currentSearch]) => {
        return searchActions.addSlotListToCurrentSearchSuccess({
          search: {
            ...currentSearch,
            slots: [
              ...new Set([
                ...(isSearchDetailedContract(currentSearch) ? (currentSearch?.slots ?? []) : []),
                ...action.slots.map((slot) => jsonLdSelectId(slot) as string),
              ]).values(),
            ],
          },
        });
      }),
    ),
  );

  public readonly setInitCurrentSystemSearch = createEffect(() =>
    this.actions.pipe(
      ofType(
        searchActions.createSearchHashSuccess,
        searchActions.appendToSearchHashSuccess,
        searchActions.removeFromSearchHashSuccess,
      ),
      filter((action) => {
        return !!action.setAsCurrentSearch && !action.isUserInitiated;
      }),
      map((action) => {
        return searchActions.initCurrentSystemSearch({ searchId: action.search.hash });
      }),
    ),
  );

  public readonly convertInitSearchToSystemSearch = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.initCurrentSystemSearch),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulingViewSelectors.timeRangeInView), isDefined()),
        this.store.pipe(select(SearchSelectors.searchEntities)),
      ]),
      exhaustMap(([action, timeRangeInView, searches]) => {
        const search = searches[action.searchId] as SearchContract;
        let filters = adjustFiltersToDateRangeToView(search, timeRangeInView);
        return from(createHash(objectToSearchExpression(filters))).pipe(
          map((hash) => {
            if (searches[hash]) {
              return searchActions.setCurrentSystemSearch({ searchId: hash });
            }
            return searchActions.createSearchHash({ filters: filters, isUserInitiated: false });
          }),
        );
      }),
    ),
  );

  public readonly convertSystemSearchOnChange = createEffect(() =>
    this.actions.pipe(
      ofType(
        schedulingViewActions.setTimeRangeInView,
        schedulingViewActions.nextRangeInView,
        schedulingViewActions.previousRangeInView,
        schedulingViewActions.changeCurrentViewSuccess,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearch), isDefined()),
        this.store.pipe(select(SchedulingViewSelectors.timeRangeInView), isDefined()),
        this.store.pipe(select(SearchSelectors.searchEntities)),
      ]),
      exhaustMap(([_action, currentSearch, timeRangeInView, searches]) => {
        let filters = adjustFiltersToDateRangeToView(currentSearch, timeRangeInView);
        return from(createHash(objectToSearchExpression(filters))).pipe(
          map((hash) => {
            if (searches[hash]) {
              return searchActions.setCurrentSystemSearch({ searchId: hash });
            }
            return searchActions.createSearchHash({
              filters: filters,
              isUserInitiated: false,
              setAsCurrentSearch: true,
            });
          }),
        );
      }),
    ),
  );

  public readonly setSystemSearchAsCurrentSearchOnCreatedSystemSearch = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.createSearchHashSuccess, searchActions.removeFromSearchHashSuccess),
      filter((action) => !action.isUserInitiated),
      map((action) => searchActions.setCurrentSystemSearch({ searchId: action.search.hash })),
    ),
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions.pipe(
      ofType(initEffectActions.startSearchQueryManagement),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions.pipe(ofType(initEffectActions.stopSearchQueryManagement))),
        ),
      ),
    );
  }
}
