import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { AssignmentsSelectors } from '@scheduler-frontend/data-access-assignment';
import { SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { exhaustMap, map } from 'rxjs';
import { findAllAssignments } from '../../functions/find-all-assignments.function';
import { findAllSlots } from '../../functions/find-all-slots.function';
import { schedulingActions } from '../actions/scheduling.actions';
import { SlotInAssignmentApi } from '../api/slot-in-assignment.api';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

@Injectable()
export class SlotDeselectingEffect {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  private readonly slotInAssignmentApi = inject(SlotInAssignmentApi);

  public deselectAllSlotsWithTheAssignmentSerie = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.deselectSlots),
      concatLatestFrom(() => [this.store.select(SlotsSelectors.slotEntities)]),
      map(([action, slots]) => {
        return schedulingActions.retrieveSlotsWithAssignmentForDeselection({
          assignments: findAllAssignments(action, slots),
        });
      }),
    ),
  );

  public retrieveSlotForDeselection = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.retrieveSlotsWithAssignmentForDeselection),
      concatLatestFrom(() => [
        this.store.select(AssignmentsSelectors.assignmentEntities).pipe(isDefined()),
      ]),
      exhaustMap(([action, assignments]) =>
        findAllSlots(action, assignments, this.slotInAssignmentApi),
      ),
      map(([slotIds, assignments]) => {
        return schedulingActions.retrieveSlotsWithAssignmentForDeselectionSuccess({
          slots: slotIds,
          assignments: assignments,
        });
      }),
    ),
  );

  public deselectRetrievedSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.retrieveSlotsWithAssignmentForDeselectionSuccess),
      map((action) => schedulingActions.deselectSlots({ items: action.slots })),
    ),
  );

  public deselectSlotsInAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.deselectSlotsInAssignment),
      concatLatestFrom(() => [this.store.select(SchedulingSelectors.selectedSlots)]),
      map(([action, selectedSlots]) => {
        const slotsToDeselect: string[] = selectedSlots
          .filter((slot: SlotContract | SlotDetailedContract) => {
            if (slot && 'assignmentHasSlot' in slot) {
              return (
                jsonLdSelectId(slot.assignmentHasSlot?.assignment?.['@id']) === action.assignment
              );
            }
            return false;
          })
          .map((slot: SlotContract | SlotDetailedContract) => jsonLdSelectId(slot));
        return schedulingActions.deselectSlots({ items: slotsToDeselect });
      }),
    ),
  );
}
