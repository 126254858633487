@if (selfAssignParametersForm(); as selfAssignParametersForm) {
  <ion-list>
    <ion-list-header data-test="self-assign-form-header">
      Zelf inplannen
      @if (activeAssignmentStoreService.savingSelfAssignmentSettings()) {
        <ion-spinner color="medium" data-test="self-assign-form-spinner" />
      }
    </ion-list-header>

    <ion-item lines="none">
      <ion-toggle
        labelPlacement="start"
        color="secondary"
        [formControl]="selfAssignableToggle()"
        (ionChange)="editSelfAssignable()"
        data-test="self-assign-form-self-assignable"
      >
        Zelf inplanbaar
      </ion-toggle>
    </ion-item>

    <ion-item lines="none">
      <ion-toggle
        labelPlacement="start"
        color="secondary"
        [formControl]="selfAssignableWhenNewToggle()"
        (ionChange)="editSelfAssignable()"
        data-test="self-assign-form-allow-self-assign-when-new"
      >
        Zelf inplanbaar indien nieuw
      </ion-toggle>
    </ion-item>
  </ion-list>

  <div class="number-inputs" [formGroup]="selfAssignParametersForm">
    <tt-number-input
      data-test="self-assign-form-min-grade"
      label="Min. cijfer"
      labelPlacement="stacked"
      fill="solid"
      [max]="10"
      [min]="1"
      [stepInterval]="0.1"
      [allowDecimals]="true"
      formControlName="minimalGradeSelfAssign"
    ></tt-number-input>

    <tt-number-input
      label="Max. aantal km"
      labelPlacement="stacked"
      fill="solid"
      [allowDecimals]="false"
      [max]="350"
      [min]="0"
      [formControl]="selfAssignParametersForm.controls.maxTravelDistanceSelfAssign"
      data-test="self-assign-form-max-travel-distance-self-assign"
    ></tt-number-input>

    <ion-button
      color="secondary"
      size="small"
      [matTooltip]="'Min. cijfer en max. aantal km opslaan'"
      [disabled]="
        selfAssignParametersForm.invalid ||
        !selfAssignableToggle().value ||
        activeAssignmentStoreService.savingSelfAssignmentSettings()
      "
      (click)="submitSelfAssignParameters()"
    >
      @if (activeAssignmentStoreService.savingSelfAssignmentSettings()) {
        <ion-spinner slot="icon-only" />
      } @else {
        <fa-icon [icon]="faFloppyDisk" slot="icon-only" />
      }
    </ion-button>
  </div>
}
