<div class="candidate">
  <ion-list>
    @let loading = assignmentStoreService.loading();
    @let candidate = activeAssignmentStoreService.assignedCandidate();

    <ion-list-header>Begeleider</ion-list-header>
    @if (loading) {
      <ion-item lines="none">
        <ion-label>
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-skeleton-text [animated]="true" style="width: 80px"></ion-skeleton-text>
      </ion-item>
      <ion-item lines="none">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-item>
    } @else if (candidate) {
      <ion-item lines="none">
        <ion-label data-test="assignment-general-fullName">
          {{ candidate?.fullName }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label data-test="assignment-general-phoneNumber">
          {{ candidate?.phoneNumber }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label data-test="assignment-general-emailAddress">
          {{ candidate?.emailAddress }}
        </ion-label>
      </ion-item>
    } @else {
      <ion-item lines="none">
        <ion-label>Geen begeleider toegewezen.</ion-label>
      </ion-item>
    }
  </ion-list>

  <ion-list>
    <ion-list-header>Locatie</ion-list-header>
    @if (activeAssignmentStoreService.assignmentLocation(); as location) {
      <ion-item lines="none">
        <ion-label>{{ location.name }}</ion-label>
      </ion-item>
    } @else {
      <ion-item lines="none">
        <ion-label>
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-label>
      </ion-item>
    }
  </ion-list>
</div>

@if (activeAssignmentsPermissionsStoreService.canReadSelfAssignable()) {
  @if (activeAssignmentStoreService.assignment(); as assignment) {
    <app-self-assign-form data-test="assignment-general-self-assign-form"></app-self-assign-form>
    <app-urgent-form></app-urgent-form>
  }
}

<app-assignment-has-slot-table></app-assignment-has-slot-table>
