<ion-list>
  <ion-list-header data-test="urgent-form-header">
    Urgentie
    @if (activeAssignmentStoreService.savingIsUrgent()) {
      <ion-spinner color="medium" data-test="urgent-form-spinner" />
    }
  </ion-list-header>
  <ion-item lines="none">
    <ion-toggle
      labelPlacement="start"
      color="secondary"
      [formControl]="urgentForm()"
      (ionChange)="editIsUrgent()"
      data-test="urgent-form-toggle"
    >
      Spoed
    </ion-toggle>
  </ion-item>
</ion-list>
