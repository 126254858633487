import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { initEffectActions } from '@scheduler-frontend/data-access-initialize';
import {
  schedulingViewActions,
  SchedulingViewSelectors,
} from '@scheduler-frontend/data-access-scheduling';
import { objectToSearchExpression } from '@scheduler-frontend/search-expression';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { PlainRangeInterface } from '@techniek-team/tt-range';
import { formatISO } from 'date-fns';
import { catchError, exhaustMap, filter, from, Observable, of, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchMetaDataContract } from '../../contract/search.contract';
import { UserSearchContract } from '../../contract/user-search.contract';
import { searchActions } from '../action/search.actions';
import { userSearchActions } from '../action/user-search.actions';
import { LoadSearchApi } from '../api/load-search.api';
import { SearchSelectors } from '../selector/search.selectors';
import { UserSearchSelectors } from '../selector/user-search.selectors';
import { adjustFiltersToDateRangeToView } from './functions/adjust-filters-to-date-range-to-view.function';
import { createHash } from './functions/create-hash.function';

@Injectable()
export class UserSearchEffects implements OnRunEffects {
  private readonly actions$ = inject(Actions);

  private readonly loadSearchApi = inject(LoadSearchApi);

  private readonly store = inject(Store);

  public readonly addNewUserSearchHashWhenUserInitiatesNewSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchActions.createSearchHashSuccess,
        searchActions.appendToSearchHashSuccess,
        searchActions.removeFromSearchHashSuccess,
      ),
      filter(({ isUserInitiated }) => isUserInitiated),
      map(({ search, isUserInitiated }) => {
        return userSearchActions.addUserSearchHash({
          search: {
            isFavorite: false,
            lastQueriedAt: formatISO(new Date()),
            search: search,
          },
        });
      }),
    ),
  );

  public readonly loadSearchHash = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.setCurrentUserSearch, userSearchActions.setColdCurrentUserSearch),
      switchMap((action) =>
        this.loadSearchApi.execute(action.searchId).pipe(
          map((response) => {
            return userSearchActions.addUserSearchHash({
              coldBoot: action.type === userSearchActions.setColdCurrentUserSearch.type,
              search: {
                lastQueriedAt: formatISO(new Date()),
                search: response,
              },
            });
          }),
          catchError((error) => of(userSearchActions.addUserSearchHashFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly loadSearchHashFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(userSearchActions.addUserSearchHashFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setCurrentUserSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchActions.createSearchHashSuccess,
        searchActions.appendToSearchHashSuccess,
        searchActions.removeFromSearchHashSuccess,
      ),
      filter((action) => {
        return !!action.setAsCurrentSearch && action.isUserInitiated;
      }),
      map((action) => {
        return userSearchActions.setCurrentUserSearch({ searchId: action.search.hash });
      }),
    ),
  );

  public readonly setRangeInViewOnUserSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.setCurrentUserSearch, userSearchActions.addUserSearchHash),
      filter((action) => {
        if (action.type !== userSearchActions.addUserSearchHash.type) {
          return true;
        }
        return !action.coldBoot;
      }),
      concatLatestFrom((action) => [
        this.store.select(UserSearchSelectors.currentSearch).pipe(isDefined()),
      ]),
      map(([action, search]) => search?.search.metaData?.filters),
      isDefined(),
      filter((filters) => !!filters['lesson.date']),
      map((filters) => {
        return schedulingViewActions.setDateAsRangeInView({
          date: (filters['lesson.date'] as PlainRangeInterface<string>).start,
        });
      }),
    ),
  );

  public readonly convertUserSearchToSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userSearchActions.setCurrentUserSearch,
        userSearchActions.setColdCurrentUserSearch,
        userSearchActions.addUserSearchHash,
      ),
      concatLatestFrom(() => [
        this.store.pipe(
          select(UserSearchSelectors.currentSearch),
          filter(
            (item): item is UserSearchContract & { search: { metaData: SearchMetaDataContract } } =>
              !!(item && item.search.metaData && item.search.metaData.filters),
          ),
        ),
        this.store.pipe(select(SchedulingViewSelectors.timeRangeInView), isDefined()),
        this.store.pipe(select(SearchSelectors.searchEntities)),
      ]),
      exhaustMap(([_action, currentUserSearch, timeRangeInView, searches]) => {
        let filters = adjustFiltersToDateRangeToView(currentUserSearch, timeRangeInView);
        return from(createHash(objectToSearchExpression(filters))).pipe(
          map((hash) => {
            if (searches[hash]) {
              return searchActions.setCurrentSystemSearch({ searchId: hash });
            }
            return searchActions.createSearchHash({ filters: filters, isUserInitiated: false });
          }),
        );
      }),
    ),
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(initEffectActions.startSearchQueryManagement),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions$.pipe(ofType(initEffectActions.stopSearchQueryManagement))),
        ),
      ),
    );
  }
}
