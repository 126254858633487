import { Injectable, Injector, inject } from '@angular/core';
import { UserServiceInterface } from '@techniek-team/common';
import { JestFn } from '@techniek-team/jest-utils';
import { BasePermission } from '../base-permission/base.permission';
import { PermissionUserInterface } from '../contracts/permission-user.interface';
import { InsufficientPermissionsError } from '../errors/insufficient-permissions.error';
import { TtPermissionConfig } from '../tt-permission.config';

interface ClassConstructor<T> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/prefer-function-type
  new (...args: any[]): T;
}

//noinspection DuplicatedCode
/**
 * Other than the {@see PermissionService} this mock serves as a public {@see user} property which can optionally be
 * set to quickly change the target user to validate the user against. If not set, it uses the user set in the
 * {@see TtPermissionConfigMock.userService} which defaults to the {@see UserServiceMock }.
 */
@Injectable()
//eslint-disable-next-line max-len
export class PermissionServiceMock<
  U = PermissionUserInterface,
  K extends PermissionUserInterface = PermissionUserInterface,
> {
  private readonly injector = inject(Injector);

  private readonly config = inject(TtPermissionConfig);

  private userService: UserServiceInterface<U>;

  public user!: K;

  constructor() {
    this.userService = this.injector.get<UserServiceInterface<U>>(this.config.userService);
  }

  public isGranted: JestFn<Promise<boolean>> = jest.fn(
    async (
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      permissionCls: ClassConstructor<any>,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      subject: any,
      ...extraArgs: unknown[]
    ) => {
      //eslint-disable-next-line new-cap
      const permission: BasePermission<U | K> = new permissionCls(this.injector);
      if (!this.user) {
        permission.user = (await this.userService.getUser()) as U;
      } else {
        permission.user = this.user;
      }
      permission.roleHierarchy = this.createRoleHierarchyMap();
      let roles: Set<string>;
      if (this.user) {
        roles = new Set(this.user.roles);
      } else {
        roles = await this.userService.getRoles().then((rolesArray) => new Set(rolesArray));
      }

      permission.roles = this.createRoleSet(roles, permission.roleHierarchy);

      try {
        return permission.validate(subject as string, ...extraArgs);
      } catch (error) {
        if (error instanceof InsufficientPermissionsError) {
          return false;
        }
        throw error;
      }
    },
  );

  public isDenied: JestFn<Promise<boolean>> = jest.fn(
    (
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      permissionCls: ClassConstructor<any>,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      subject: any,
      ...extraArgs: unknown[]
    ) => {
      return this.isGranted(permissionCls, subject, ...extraArgs).then((isGranted) => {
        return !isGranted;
      });
    },
  );

  public is: JestFn<Promise<boolean>> = jest.fn(async (role: string) => {
    let roles: Set<string>;
    if (this.user) {
      roles = new Set(this.user.roles);
    } else {
      roles = await this.userService.getRoles().then((rolesArray) => new Set(rolesArray));
    }

    return this.createRoleSet(roles, this.createRoleHierarchyMap()).has(role);
  });

  public isOneOf: JestFn<Promise<boolean>> = jest.fn(async (roles: string[]) => {
    const promises: Promise<boolean>[] = [];

    for (let role of roles) {
      promises.push(this.is(role));
    }

    const results: boolean[] = await Promise.all(promises);
    return !!results.filter((result: boolean) => result).length;
  });

  private createRoleHierarchyMap(): Map<string, string[]> {
    return new Map<string, string[]>(
      Object.entries<string | string[]>(this.config.roleHierarchy ?? {}).map((tuple) => {
        if (Array.isArray(tuple[1])) {
          return tuple as [string, string[]];
        }

        return [tuple[0], [tuple[1]]] as [string, string[]];
      }),
    );
  }

  private createRoleSet(roles: Set<string>, roleHierarchy: Map<string, string[]>): Set<string> {
    for (let [key, value] of roleHierarchy.entries()) {
      if (roles.has(key)) {
        roles = this.addRole(roles, value, roleHierarchy);
      }
    }
    return roles;
  }

  private addRole(
    roles: Set<string>,
    roleHierarchy: string[],
    completeHierarchy: Map<string, string[]>,
  ): Set<string> {
    for (let item of roleHierarchy) {
      if (completeHierarchy.has(item)) {
        this.addRole(roles, completeHierarchy.get(item) as string[], completeHierarchy);
        continue;
      }
      roles.add(item);
    }
    return roles;
  }
}
