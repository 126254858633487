import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonItem, IonList, IonListHeader, IonSpinner, IonToggle } from '@ionic/angular/standalone';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
} from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-urgent-form',
  templateUrl: './urgent-form.component.html',
  styleUrls: ['./urgent-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, IonList, IonListHeader, IonSpinner, IonItem, IonToggle],
})
export class UrgentFormComponent {
  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  protected readonly urgentForm = computed(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    return new FormControl(
      { value: assignment?.isUrgent ?? false, disabled: true },
      { nonNullable: true },
    );
  });

  protected readonly canMarkAsUrgent = effect(() => {
    const granted = this.activeAssignmentsPermissionsStoreService.canMarkAsUrgent();
    if (granted) {
      this.urgentForm().enable({ emitEvent: false });
    } else {
      this.urgentForm().disable({ emitEvent: false });
    }
  });

  protected editIsUrgent(): void {
    const formValue = this.urgentForm().getRawValue();

    this.activeAssignmentStoreService.markActiveAssignmentAsUrgent(formValue);
  }
}
