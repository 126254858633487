import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { objectToSearchExpression } from '@scheduler-frontend/search-expression';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Filters, SearchContract } from '../../contract/search.contract';

@Injectable({
  providedIn: 'root',
})
export class CreateSearchHashApi {
  protected readonly http = inject(HttpClient);
  /**
   * Create a search-hash which matches a search query. This can easily be shared between users.
   */
  public execute(
    filters: Filters,
    isUserInitiated: boolean = true,
    query?: string,
  ): Observable<SearchContract> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search`;
    query = query ? query : objectToSearchExpression(filters);
    const params: HttpParams = new HttpParams().appendAll({
      search: query,
      isUserInitiated: isUserInitiated ? '1' : '0',
    });
    return this.http.get<{ token: string }>(url, { params: params }).pipe(
      map((object) => ({
        hash: object.token,
        metaData: {
          query: query,
          filters: filters,
        },
      })),
    );
  }
}
