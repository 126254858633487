import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { BasePermission } from '../base-permission/base.permission';
import { PermissionUserInterface } from '../contracts/permission-user.interface';
import { PermissionService } from '../service/permission.service';

interface ClassConstructor<T> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/prefer-function-type
  new (...args: any[]): T;
}

//noinspection AngularMissingOrInvalidDeclarationInModule
/**
 * This directive gives the ability to use the {@see PermissionService.isGranted}
 * method from your template.
 *
 * @example
 * ```html
 *  <div *ttIsGranted="AssignmentPermission; subject: 'CREATE_ASSIGNMENT_COMPENSATION_LINE'; extraArgs:
 *   [this.assignment]"
 *    <!-- html where you needed permission for -->
 *  </div>
 * ```
 */
@Directive({
  selector: '[ttIsGranted],[*ttIsGranted]',
  standalone: true,
})
export class IsGrantedDirective<U extends PermissionUserInterface = PermissionUserInterface>
  implements OnInit
{
  private readonly permissionService = inject<PermissionService<U>>(PermissionService);

  private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);

  private readonly viewContainer = inject(ViewContainerRef);

  @Input() public set ttIsGranted(permissionCls: ClassConstructor<BasePermission<U>>) {
    this.permissionCls = permissionCls;
  }

  @Input() public set ttIsGrantedSubject(subject: string) {
    this.subject = subject;
  }

  @Input() public set ttIsGrantedExtraArgs(extraArgs: unknown[]) {
    this.extraArgs = extraArgs ?? [];
  }

  @Input() public ttIsGrantedElse?: TemplateRef<unknown>;

  private hasView: boolean = false;

  private permissionCls!: ClassConstructor<BasePermission<U>>;

  private subject!: string;

  private extraArgs: unknown[] = [];

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.permissionService
      .isGranted(this.permissionCls, this.subject, ...(this.extraArgs ?? []))
      .then((isGranted) => {
        this.viewContainer.clear();

        if (isGranted) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (this.ttIsGrantedElse) {
          this.viewContainer.createEmbeddedView(this.ttIsGrantedElse);
          this.hasView = false;
        } else {
          this.hasView = false;
        }
      });
  }
}
