import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import {
  initEffectActions,
  InitializeSelectors,
  SchedulingViewQueryParams,
} from '@scheduler-frontend/data-access-initialize';
import { environment } from '@scheduler-frontend/environments';
import { RoutesView } from '@scheduler-frontend/scheduling-common';
import { consoleInDev } from '@techniek-team/common';
import { filter, map } from 'rxjs';
import { schedulingViewActions } from '../actions/scheduling-view.actions';
import { schedulingActions } from '../actions/scheduling.actions';

@Injectable()
export class InitSchedulingEffects {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  public readonly setStoreByQueryParamsOnColdBoot = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [
        this.store.select(InitializeSelectors.currentRoute),
        this.store.select(InitializeSelectors.selectRouteNestedParams),
        this.store.select(InitializeSelectors.selectRouteNestedQueryParams),
        this.store.select(InitializeSelectors.selectRouteNestedData),
      ]),
      filter(([_action, route, params, queryParams, data]) => !route),
      map(([_action, route, params, queryParams, data]) => {
        consoleInDev(environment.debug).info(
          `%cINITIATED COLD BOOT`,
          `background: #5dade26e; border-radius: 5px; padding: 8px; color: #e8f8f5; font-weight: bold; font-size: 1rem`,
        );
        return schedulingViewActions.setSchedulingViewState({
          view: data[SchedulingViewQueryParams.VIEW] ?? undefined,
          dateInView:
            data[SchedulingViewQueryParams.VIEW] !== RoutesView.TABLE
              ? queryParams[SchedulingViewQueryParams.DATE_IN_VIEW]
              : undefined,
          selectedSlots: this.sanitizeQueryParam(
            queryParams[SchedulingViewQueryParams.SELECTED_SLOTS],
          ),
          selectedProductTypes: this.sanitizeQueryParam(
            queryParams[SchedulingViewQueryParams.SELECTED_PRODUCT_TYPES],
          ),
          selectedLocations: this.sanitizeQueryParam(
            queryParams[SchedulingViewQueryParams.SELECTED_LOCATIONS],
          ),
          activeLocation: params[SchedulingViewQueryParams.ACTIVE_LOCATION] ?? undefined,
          activeCandidate: queryParams[SchedulingViewQueryParams.ACTIVE_CANDIDATE] ?? undefined,
          selectedCandidates: this.sanitizeQueryParam(
            queryParams[SchedulingViewQueryParams.SELECTED_CANDIDATES],
          ),
        });
      }),
    );
  });

  public readonly setStoreOnRouteChange = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [
        this.store.select(InitializeSelectors.currentRoute),
        this.store.select(InitializeSelectors.selectRouteNestedData),
        this.store.select(InitializeSelectors.selectRouteNestedQueryParams),
      ]),
      filter(([_action, route, data]) => !!route || !data[SchedulingViewQueryParams.VIEW]),
      map(([_action, route, data, queryParams]) => {
        return schedulingViewActions.setSchedulingViewState({
          view: data[SchedulingViewQueryParams.VIEW] ?? undefined,
          dateInView:
            data[SchedulingViewQueryParams.VIEW] !== RoutesView.TABLE
              ? queryParams[SchedulingViewQueryParams.DATE_IN_VIEW]
              : undefined,
        });
      }),
    );
  });

  public readonly setActiveCandidateOnColdBoot = createEffect(() => {
    return this.actions$.pipe(
      ofType(schedulingViewActions.setSchedulingViewState),
      filter((action) => !!action.activeCandidate),
      map((action) => {
        return candidatesActions.setActiveCandidate({
          selectedId: action.activeCandidate as string,
        });
      }),
    );
  });

  public clearSelectionOnStoppingSchedulingBySearch = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.stopSelectedSlotsManagement),
      map(() => schedulingActions.clearAllSelections()),
    ),
  );

  public showSlotSelectionCheckbox = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.startSelectingSlots),
      map(() => schedulingViewActions.showSlotSelectionCheckbox()),
    ),
  );

  public hideSlotSelectionCheckbox = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.stopSelectingSlots),
      map(() => schedulingViewActions.hideSlotSelectionCheckbox()),
    ),
  );

  public showInlineAssignButtons = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.startShowInlineAssignButtons),
      map(() => schedulingViewActions.showInlineAssignButtons()),
    ),
  );

  public hideInlineAssignButtons = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.stopShowInlineAssignButtons),
      map(() => schedulingViewActions.hideInlineAssignButtons()),
    ),
  );

  private sanitizeQueryParam(value: string | string[] | undefined): string[] | undefined {
    if (!value) {
      return undefined;
    }
    return ([] as string[]).concat(value);
  }
}
