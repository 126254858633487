import { inject } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { initEffectActions } from '@scheduler-frontend/data-access-initialize';
import { schedulingActions } from '@scheduler-frontend/data-access-scheduling';
import { isDefined } from '@techniek-team/rxjs';
import { exhaustMap, filter, Observable, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSearchDetailedContract } from '../../contract/search.contract';
import { searchActions } from '../action/search.actions';
import { SearchSelectors } from '../selector/search.selectors';

export class SearchSchedulingManagementEffect {
  private readonly store = inject(Store);

  private readonly actions = inject(Actions);

  public readonly startLoadingSlots = createEffect(() =>
    this.actions.pipe(
      ofType(searchActions.setCurrentSystemSearchSuccess, searchActions.reloadCurrentSearch),
      map(() => schedulingActions.startLoadingSlots()),
    ),
  );

  public readonly setSchedulingSlots = createEffect(() =>
    this.actions.pipe(
      ofType(
        searchActions.addSlotListToCurrentSearchSuccess,
        searchActions.setCurrentSystemSearchSuccess,
      ),
      concatLatestFrom(() => this.store.pipe(select(SearchSelectors.currentSearch), isDefined())),
      map(([action, search]) => search),
      filter(isSearchDetailedContract),
      map((search) =>
        schedulingActions.setSlotList({
          items: search.slots as string[],
        }),
      ),
    ),
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions.pipe(
      ofType(initEffectActions.startScheduleManagement),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions.pipe(ofType(initEffectActions.stopScheduleManagement))),
        ),
      ),
    );
  }
}
