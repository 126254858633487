import { Dictionary } from '@ngrx/entity/src/models';
import {
  AssignmentContract,
  AssignmentDetailedContract,
} from '@scheduler-frontend/assignment-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { firstValueFrom, from, map } from 'rxjs';
import { schedulingActions } from '../+state/actions/scheduling.actions';
import { SlotInAssignmentApi } from '../+state/api/slot-in-assignment.api';

export function findAllSlots(
  action:
    | ReturnType<typeof schedulingActions.retrieveSlotsWithAssignmentForSelection>
    | ReturnType<typeof schedulingActions.retrieveSlotsWithAssignmentForDeselection>,
  assignmentsDict: Dictionary<AssignmentDetailedContract | AssignmentContract>,
  slotInAssignmentApi: SlotInAssignmentApi,
) {
  return from(
    Promise.all(
      action.assignments.map((assignmentId) => {
        const foundAssignment = assignmentsDict[assignmentId];
        if (foundAssignment && 'assignmentHasSlots' in foundAssignment) {
          return Promise.resolve(foundAssignment);
        }
        return firstValueFrom(slotInAssignmentApi.execute(assignmentId));
      }),
    ),
  ).pipe(
    map(
      (assignments) =>
        [
          assignments
            .map((assignment) => assignment.slotsInSeries ?? [])
            .flat(1)
            .map((slot) => jsonLdSelectId(slot)),
          assignments,
        ] as [string[], AssignmentDetailedContract[]],
    ),
  );
}
