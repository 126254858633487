import { searchExpressionToObject } from '@scheduler-frontend/search-expression';
import { SearchContract } from '../../contract/search.contract';
import { UserSearchContract } from '../../contract/user-search.contract';
import { GetHashResponseV1, GetUserSearchesResponseV1 } from './search.response';

export function convertGetUserSearchesResponseFunction(
  response: GetUserSearchesResponseV1,
): UserSearchContract {
  return {
    isFavorite: response.isFavorite,
    lastQueriedAt: response.lastQueriedAt,
    search: convertGetHashResponseV1ToV3(response.search),
  };
}

export function convertGetHashResponseV1ToV3(response: GetHashResponseV1): SearchContract {
  return {
    hash: response.hash,
    metaData: {
      query: response.query,
      filters: searchExpressionToObject(response.query),
    },
  };
}
