import { Params } from '@angular/router';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';
import { environment } from '@scheduler-frontend/environments';
import { consoleInDev } from '@techniek-team/common';
import { initializeActions } from './initialize.actions';

export const INITIALIZE_FEATURE_KEY = 'Initialize';

export type RouterState = SerializedRouterStateSnapshot & {
  nestedQueryParams?: Params;
  nestedData?: Params;
  nestedParams?: Params;
  routeId: string;
};

// we only hold a list of candidate iri which can be retrieved from the candidates store
export interface InitializeState {
  routerHistory: RouterState[];
}
export const initialInitializeState: InitializeState = {
  routerHistory: [] as RouterState[],
};

const reducer = createReducer(
  initialInitializeState,
  on(initializeActions.addRouterHistory, (state, { history }) => {
    if (state.routerHistory.at(-1)?.routeId === history.routeId) {
      consoleInDev(environment.debug).group(
        '%c Updating Routing History',
        `background: #ffbb23; color: #6b1fa3; font-weight: bold;`,
      );
      consoleInDev(environment.debug).info(
        `\x1B[1mUrl:\x1B[m ${history.url} \n But now actions new queryParam string of:`,
      );
      consoleInDev(environment.debug).info(`But now actions new queryParams:`);
      consoleInDev(environment.debug).dir(history.nestedQueryParams, { showHidden: false });
      consoleInDev(environment.debug).groupEnd();
      return {
        ...state,
        routerHistory: [...state.routerHistory.slice(0, -1), history],
      };
    }
    consoleInDev(environment.debug).group(
      '%c Adding Routing History',
      `background: #34fa31; color: #6b1fa3; font-weight: bold;`,
    );
    consoleInDev(environment.debug).info(`\x1B[1mUrl:\x1B[m ${history.url}`);
    consoleInDev(environment.debug).info(`With queryParams:`);
    consoleInDev(environment.debug).dir(history.nestedQueryParams, { showHidden: false });
    consoleInDev(environment.debug).groupEnd();
    return {
      ...state,
      routerHistory: [...state.routerHistory, history],
    };
  }),
);

export function initializeReducer(
  state: InitializeState | undefined,
  action: Action,
): InitializeState {
  return reducer(state, action);
}
