import { DefaultUrlSerializer, Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INITIALIZE_FEATURE_KEY, InitializeState } from './initialize.reducer';

export class InitializeSelectors {
  public static urlSerializer = new DefaultUrlSerializer();

  public static readonly selectRouter = createFeatureSelector<RouterReducerState>('router');

  public static readonly initializeState =
    createFeatureSelector<InitializeState>(INITIALIZE_FEATURE_KEY);

  public static readonly selectRouterState = createSelector(
    InitializeSelectors.selectRouter,
    (router) => router.state,
  );

  public static readonly routeHistory = createSelector(
    InitializeSelectors.initializeState,
    (state) => state.routerHistory,
  );

  public static readonly currentRoute = createSelector(
    InitializeSelectors.routeHistory,
    (routerHistory) => (routerHistory.length > 0 ? routerHistory.at(-1) : undefined),
  );

  public static readonly currentRoutePrimaryNavigationPath = createSelector(
    InitializeSelectors.currentRoute,
    (route) => {
      if (!route) {
        return undefined;
      }

      return [
        '/',
        ...InitializeSelectors.urlSerializer
          .parse(route?.url as string)
          .root.children['primary'].segments.map((segment) => segment.path),
      ];
    },
  );

  public static readonly previousRoute = createSelector(
    InitializeSelectors.routeHistory,
    (routerHistory) => {
      return routerHistory.length > 1 ? routerHistory.at(-2) : undefined;
    },
  );

  public static readonly previousRoutePrimaryNavigationPath = createSelector(
    InitializeSelectors.previousRoute,
    (route) => {
      if (!route) {
        return undefined;
      }
      return [
        '/',
        ...InitializeSelectors.urlSerializer
          .parse(route?.url as string)
          .root.children['primary'].segments.map((segment) => segment.path),
      ];
    },
  );

  public static readonly selectRouteNestedData = createSelector(
    InitializeSelectors.selectRouter,
    (router) => {
      let currentRoute = router?.state?.root;
      let data: Params = {};
      while (currentRoute?.firstChild) {
        currentRoute = currentRoute.firstChild;
        data = {
          ...data,
          ...currentRoute.data,
        };
      }
      return data;
    },
  );

  public static readonly selectRouteNestedQueryParams = createSelector(
    InitializeSelectors.selectRouter,
    (router) => {
      let currentRoute = router?.state?.root;
      let queryParams: Params = {};
      while (currentRoute?.firstChild) {
        currentRoute = currentRoute.firstChild;
        queryParams = {
          ...queryParams,
          ...currentRoute.queryParams,
        };
      }
      return queryParams;
    },
  );

  public static readonly selectRouteNestedParams = createSelector(
    InitializeSelectors.selectRouter,
    (router) => {
      let currentRoute = router?.state?.root;
      let params: Params = {};
      while (currentRoute?.firstChild) {
        currentRoute = currentRoute.firstChild;
        params = {
          ...params,
          ...currentRoute.params,
        };
      }
      return params;
    },
  );

  public static readonly selectRouteNestedDataKey = <T>(param: string) =>
    createSelector(
      InitializeSelectors.selectRouteNestedData,
      (params): T => params && (params[param] as T),
    );

  public static readonly selectRouteNestedQueryParam = <T>(param: string) =>
    createSelector(
      InitializeSelectors.selectRouteNestedQueryParams,
      (params): T => params && (params[param] as T),
    );

  public static readonly selectRouteNestedParam = (param: string) =>
    createSelector(
      InitializeSelectors.selectRouteNestedParams,
      (params) => params && (params[param] as string | undefined),
    );
}
