import { inject } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { initEffectActions } from '@scheduler-frontend/data-access-initialize';
import { slotsActions, SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { exhaustMap, Observable, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { schedulingViewActions } from '../actions/scheduling-view.actions';

export class OnlySelectedSlotsEffect implements OnRunEffects {
  private readonly actions = inject(Actions);

  private readonly store = inject(Store);

  public loadSlotForMissingSelectedSlots = createEffect(() => {
    return this.actions.pipe(
      ofType(schedulingViewActions.setSchedulingViewState),
      concatLatestFrom(() => this.store.select(SlotsSelectors.slotEntities)),
      map(([action, slots]) => {
        const missingSlots: string[] = [];
        for (let slot of action.selectedSlots ?? []) {
          if (!slots[slot]) {
            missingSlots.push(slot);
          }
        }
        return slotsActions.loadSlots({ slotIds: missingSlots, setAsSlotList: true });
      }),
    );
  });

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions.pipe(
      ofType(initEffectActions.startCandidateAssign),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions.pipe(ofType(initEffectActions.stopCandidateAssign))),
        ),
      ),
    );
  }
}
