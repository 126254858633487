import { NgModule } from '@angular/core';
import { TtTimeInputControlComponent } from './tt-time-input-control.component';

/**
 * @deprecated Use standalone `TtTimeInputControlComponent` instead.
 */
@NgModule({
  imports: [TtTimeInputControlComponent],
  exports: [TtTimeInputControlComponent],
})
export class TtTimeInputModule {}
