<div class="status">
  @if (this.assignmentHasSlot().purpose === AssignmentHasSlotPurposeEnum.ABSENCE) {
    <fa-icon [icon]="faVirus"> </fa-icon>
  }
</div>

<div class="date">
  {{ this.assignmentHasSlot().slot.timePeriod.start | date: 'dd-MM-yyyy' }}
</div>

<div class="time">
  {{ this.assignmentHasSlot().slot.timePeriod.start | date: 'HH:mm' }}
  -
  {{ this.assignmentHasSlot().slot.timePeriod.end | date: 'HH:mm' }}
</div>

<ng-container [formGroup]="form">
  <div class="break-input">
    <tt-number-input
      formControlName="breakTime"
      data-test="assignment-has-slot-break-input"
      type="text"
      aria-label="pauze"
      fill="solid"
      [stepInterval]="5"
      [placeholder]="0"
    ></tt-number-input>

    <ion-note *ngxControlError="form.controls.breakTime; track: 'required'">
      Voer een geldige waarde in.
    </ion-note>

    <ion-note *ngxControlError="form; track: 'breakIsLongerThanWorkingTimes'">
      De pauze is langer dan de lengte van de shift.
    </ion-note>
  </div>

  <div class="time-input" formGroupName="actualTimePeriod">
    @if (!this.assignmentHasSlot().slot.isCombined) {
      <div class="controls">
        <tt-time-control
          formControlName="start"
          ariaLabel="start time"
          fill="solid"
          data-test="assignment-has-slot--actualTime-start"
        ></tt-time-control>
        -
        <tt-time-control
          formControlName="end"
          ariaLabel="end time"
          fill="solid"
          data-test="assignment-has-slot--actualTime-end"
        ></tt-time-control>

        @let savingActualWorkingTimes = assignmentsStoreService.savingActualWorkingTimes();
        @let targetAssignmentHasSlotId =
          assignmentsStoreService.targetAssignmentHasSlotIdActualWorkingTimes();

        <ion-button
          color="secondary"
          size="small"
          [matTooltip]="'Werktijden en pauze goedkeuren'"
          [disabled]="savingActualWorkingTimes || form.invalid || form.disabled"
          (click)="confirmTime()"
        >
          @if (
            savingActualWorkingTimes &&
            targetAssignmentHasSlotId === (this.assignmentHasSlot() | jsonLdSelect)
          ) {
            <ion-spinner slot="icon-only" />
          } @else {
            <fa-icon [icon]="faCheck" slot="icon-only" />
          }
        </ion-button>
      </div>

      <ion-note *ngxControlError="form.controls.actualTimePeriod; track: 'tsRangeNotInRange'">
        De eindtijd moet later zijn dan de starttijd.
      </ion-note>

      <ion-note
        data-test="assignment-has-slot-start-time-invalid"
        *ngxControlError="form.controls.actualTimePeriod.controls.start; track: 'required'"
      >
        Starttijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
      </ion-note>

      <ion-note
        data-test="assignment-has-slot-end-time-invalid"
        *ngxControlError="form.controls.actualTimePeriod.controls.end; track: 'required'"
      >
        Eindtijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
      </ion-note>
    }
  </div>
</ng-container>

<div class="role">
  {{ targetRole()?.name }}
</div>

<ion-toggle
  class="saldo"
  data-test="assignment-has-slot-saldo-input"
  color="secondary"
  aria-label="heeft saldo"
  (ionChange)="changeIsBillable()"
  [disabled]="
    (assignmentsStoreService.savingIsBillable$ | async) === true ||
    activeAssignmentsPermissionsStoreService.canChangeIsBillable() ||
    this.assignmentHasSlot().purpose !== AssignmentHasSlotPurposeEnum.SCHEDULING
  "
  [checked]="this.assignmentHasSlot().slot.lesson.isBillable"
>
</ion-toggle>

<ion-button
  class="actions"
  [id]="'popover-button-' + (this.assignmentHasSlot().slot | jsonLdSelect)"
  fill="clear"
  size="small"
  color="medium"
>
  <fa-icon [icon]="faEllipsisVertical" />
</ion-button>

<ion-popover
  [trigger]="'popover-button-' + (this.assignmentHasSlot().slot | jsonLdSelect)"
  [showBackdrop]="false"
  [dismissOnSelect]="true"
>
  <ng-template>
    <ion-content>
      <ion-list>
        @if (activeAssignmentStoreService.assignment()?.state === AssignmentStateEnum.UNASSIGNED) {
          <ion-item
            [matTooltip]="
              (canRemoveSlot() | async)
                ? 'Verwijder shift uit deze opdracht.'
                : 'Deze shift kan niet worden verwijderd uit deze opdracht.'
            "
            (click)="removeSlot()"
            [disabled]="(canRemoveSlot() | async) === false"
            [button]="true"
            [detail]="false"
            lines="none"
          >
            <fa-icon [icon]="faTrashCan" slot="start"></fa-icon>
            Shift ontkoppelen
          </ion-item>
        }

        @if (this.assignmentHasSlot().purpose === AssignmentHasSlotPurposeEnum.SCHEDULING) {
          <ion-item
            (click)="unassignSlot()"
            [disabled]="
              this.assignmentHasSlot().slot.displayAsCombined &&
              !this.assignmentHasSlot().slot.isCombined
            "
            [button]="true"
            [detail]="false"
            lines="none"
          >
            <fa-icon [icon]="faArrowRightFromBracket" slot="start"></fa-icon>
            Uitroosteren
          </ion-item>
        }

        @if (this.assignmentHasSlot().purpose === AssignmentHasSlotPurposeEnum.ABSENCE) {
          <ion-item
            [disabled]="
              this.assignmentHasSlot().slot.displayAsCombined &&
              !this.assignmentHasSlot().slot.isCombined
            "
            (click)="markAsPresent()"
            [button]="true"
            [detail]="false"
            lines="none"
          >
            <fa-icon [icon]="faVirus" slot="start"></fa-icon>
            Ziekmelden ongedaan maken
          </ion-item>
        }
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
