import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

/**
 * To check if a toggle is enabled from within a Component template, you can use
 * the ttFeatureFlag and ttFeatureFlagNot directives:
 *
 * @example
 * ```angular-html
 *   <some-component *ttFeatureFlag="'test.toggle'"></some-component>
 *   <some-other-component *ttFeatureFlagNot="'test.toggle'"></some-other-component>
 * ```
 */
@Directive({
  selector: '[ttFeatureFlagNot],[*ttFeatureFlagNot]',
  standalone: true,
})
export class TtFeatureFlagNotDirective implements OnInit {
  private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);

  private readonly viewContainer = inject(ViewContainerRef);

  private readonly ttFeatureFlagsService = inject(TtFeatureFlagsService);

  private readonly changeDetectionRef = inject(ChangeDetectorRef);

  /**
   * boolean stating if the content within this directive is available.
   */
  private isVisible: boolean = false;

  /**
   * The name of the feature toggle. This is used to check if the feature should
   * still be shown or not on a update from the backend.
   */
  private toggleName: string = '';

  @Input()
  public set ttFeatureFlagNot(toggleName: string) {
    if (!this.ttFeatureFlagsService.isEnabled(toggleName)) {
      if (!this.isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isVisible = true;
      }
    } else {
      this.viewContainer.clear();
      this.isVisible = false;
    }

    this.toggleName = toggleName;
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.ttFeatureFlagsService.onUpdate.subscribe(() => {
      this.ttFeatureFlagNot = this.toggleName;
      this.changeDetectionRef.markForCheck();
    });
  }
}
