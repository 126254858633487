import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { formatISO, isAfter, subDays } from 'date-fns';
import { catchError, exhaustMap, from, map, of, switchMap, tap } from 'rxjs';
import { RegionApi } from './api/region/region.api';
import { regionsActions } from './regions.actions';
import { REGIONS_FEATURE_KEY } from './regions.reducer';

@Injectable()
export class RegionsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly errorHandler = inject(SentryErrorHandler);

  private readonly toastService = inject(ToastService);

  private readonly storage = inject(Storage);

  private readonly regionApi = inject(RegionApi);

  private readonly store = inject(Store);

  public saveRegionsToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(regionsActions.loadRegionsSuccess),
        tap((action) => {
          if (action.regions.length === 0) {
            return;
          }
          return from(
            Promise.all([
              this.storage.set(`${REGIONS_FEATURE_KEY}-store-cache`, {
                items: action.regions,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  public readonly initRegions = createEffect(() =>
    this.actions$.pipe(
      ofType(regionsActions.initRegions),
      exhaustMap((action) =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return from(this.storage.get(`${REGIONS_FEATURE_KEY}-store-cache`)).pipe(
              exhaustMap((cache) => {
                if (cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    regionsActions.loadFromCacheRegionsSuccess({
                      regions: cache.items,
                      totalItems: cache.items.length,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.regionApi.getRegions().pipe(
                  map((regions) =>
                    regionsActions.loadRegionsSuccess({
                      regions: regions['hydra:member'],
                      totalItems: regions['hydra:totalItems'],
                    }),
                  ),
                );
              }),
              catchError((error) => of(regionsActions.loadRegionsFailure({ error: error }))),
            );
          }),
        ),
      ),
    ),
  );

  public readonly reloadRegions = createEffect(() =>
    this.actions$.pipe(
      ofType(regionsActions.reloadRegions),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return this.regionApi.getRegions().pipe(
              map((regions) =>
                regionsActions.loadRegionsSuccess({
                  regions: regions['hydra:member'],
                  totalItems: regions['hydra:totalItems'],
                }),
              ),
              catchError((error) => of(regionsActions.loadRegionsFailure({ error: error }))),
            );
          }),
        ),
      ),
    ),
  );

  public readonly initRegionsFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(regionsActions.loadRegionsFailure),
        tap((action) => {
          return Promise.all([
            this.errorHandler.captureError(action.error),
            this.toastService.error({
              message: "Er is iets misgegaan bij het laden van Regio's.",
              duration: 10000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }),
          ]);
        }),
      ),
    { dispatch: false },
  );

  public ngrxOnInitEffects(): Action {
    return regionsActions.initRegions();
  }
}
