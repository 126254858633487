import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSearchContract } from '../../contract/user-search.contract';
import { convertGetUserSearchesResponseFunction } from './convert-get-user-searches-response.function';
import { GetUserSearchesResponseV1 } from './search.response';

@Injectable({
  providedIn: 'root',
})
export class RecentSearchesApi {
  protected readonly http = inject(HttpClient);

  public execute(): Observable<UserSearchContract[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search/recents`;

    return this.http
      .get<GetUserSearchesResponseV1[]>(url)
      .pipe(
        map((response) => response.map((item) => convertGetUserSearchesResponseFunction(item))),
      );
  }
}
