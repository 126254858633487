import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, from, switchMap, tap } from 'rxjs';
import { CreateSlotModalComponent } from '../../create-slot-modal/create-slot-modal.component';
import { createSlotsActions } from '../actions/create-slots.actions';

@Injectable()
export class OpenCreateSlotsEffects {
  private readonly actions$ = inject(Actions);

  private readonly modalController = inject(ModalController);

  public readonly openCreateSlotModal = createEffect(() =>
    this.actions$.pipe(
      ofType(createSlotsActions.openCreateSlotModal),
      exhaustMap(() => {
        return from(
          this.modalController.create({
            id: 'create-slot-modal',
            component: CreateSlotModalComponent,
            cssClass: ['create-slot-modal'],
          }),
        ).pipe(
          switchMap((modal) => modal.present().then(() => modal)),
          switchMap((modal) => modal.onWillDismiss().then(() => createSlotsActions.reset())),
        );
      }),
    ),
  );

  public readonly dismissOnSubmitSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSlotsActions.createSlotSuccess),
        tap(() =>
          this.modalController.getTop().then((modal) => {
            if (modal) {
              modal.canDismiss = true;
            }
          }),
        ),
        tap(() => this.modalController.dismiss()),
      ),
    { dispatch: false },
  );
}
