import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { AssignmentsSelectors } from '@scheduler-frontend/data-access-assignment';
import { SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { exhaustMap, map } from 'rxjs';
import { filter } from 'rxjs/operators';
import { findAllAssignments } from '../../functions/find-all-assignments.function';
import { findAllSlots } from '../../functions/find-all-slots.function';
import { schedulingActions } from '../actions/scheduling.actions';
import { SlotInAssignmentApi } from '../api/slot-in-assignment.api';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

@Injectable()
export class SlotSelectingEffect {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  private readonly slotInAssignmentApi = inject(SlotInAssignmentApi);

  public selectAllSlotsWithTheAssignmentSerie = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.selectSlots),
      concatLatestFrom(() => [this.store.select(SlotsSelectors.slotEntities)]),
      map(([action, slots]) => findAllAssignments(action, slots)),
      filter((assignmentIds) => assignmentIds.length > 0),
      map((assignmentIds) => {
        return schedulingActions.retrieveSlotsWithAssignmentForSelection({
          assignments: assignmentIds,
        });
      }),
    ),
  );

  public retrieveSlotForSelection = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.retrieveSlotsWithAssignmentForSelection),
      concatLatestFrom(() => [
        this.store.select(AssignmentsSelectors.assignmentEntities).pipe(isDefined()),
      ]),
      exhaustMap(([action, assignments]) =>
        findAllSlots(action, assignments, this.slotInAssignmentApi),
      ),
      map(([slotIds, assignments]) => {
        return schedulingActions.retrieveSlotsWithAssignmentForSelectionSuccess({
          slots: slotIds,
          assignments: assignments,
        });
      }),
    ),
  );

  public selectRetrievedSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.retrieveSlotsWithAssignmentForSelectionSuccess),
      map((action) => schedulingActions.selectSlots({ items: action.slots })),
    ),
  );

  public selectAllSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.selectAllSlots),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.slotsInView).pipe(isDefined()),
      ]),
      map(([_action, slotList]) => {
        const slotIds: string[] = slotList.map((slot: SlotContract | SlotDetailedContract) =>
          jsonLdSelectId(slot),
        );

        return schedulingActions.selectSlots({ items: slotIds });
      }),
    ),
  );

  public selectSlotsInAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.selectSlotsInAssignment),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.slotsInView).pipe(isDefined()),
      ]),
      map(([action, slotList]) => {
        const slotsToSelect: string[] = slotList
          .filter((slot: SlotContract | SlotDetailedContract) => {
            if (slot && 'assignmentHasSlot' in slot) {
              return (
                jsonLdSelectId(slot.assignmentHasSlot?.assignment?.['@id']) === action.assignment
              );
            }
            return false;
          })
          .map((slot: SlotContract | SlotDetailedContract) => jsonLdSelectId(slot));
        return schedulingActions.selectSlots({ items: slotsToSelect });
      }),
    ),
  );
}
