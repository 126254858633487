import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { PlainRangeInterface } from '@techniek-team/tt-range';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchSlotApi {
  protected readonly http = inject(HttpClient);

  public execute(
    searchHash: string,
    options: {
      withRelatedShifts: boolean;
      page: number;
    } = { withRelatedShifts: false, page: 1 },
  ): Observable<Collection<SlotDetailedContract>> {
    //todo pagination is not yet available on the backend.

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/search/${searchHash}/results`;
    const params: HttpParams = new HttpParams().set(
      'withRelatedShifts',
      options.withRelatedShifts ? '1' : '0',
    );

    return this.http.get<Collection<SlotDetailedContract>>(url, { params: params }).pipe(
      map((response) => {
        return {
          ...response,
          'hydra:member': response['hydra:member'].map((item) => {
            if ('inclusiveStart' in item.timePeriod) {
              return item;
            }
            return {
              ...item,
              timePeriod: {
                ...(item.timePeriod as PlainRangeInterface),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inclusiveStart: (item.timePeriod as any).startInclusive,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inclusiveEnd: (item.timePeriod as any).endInclusive,
              },
            };
          }),
        };
      }),
    );
  }
}
