import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

export function ttFeatureFlagsGuard(feature: string, redirectUrl?: string[]): CanActivateFn {
  return () => {
    const ttFeatureFlagsService: TtFeatureFlagsService = inject(TtFeatureFlagsService);
    const router: Router = inject(Router);

    return ttFeatureFlagsService.isInitialized.pipe(
      filter((isInitialized) => isInitialized),
      map(() => {
        if (ttFeatureFlagsService.isEnabled(feature)) {
          return true;
        }

        if (redirectUrl) {
          return router.createUrlTree(redirectUrl);
        }

        return false;
      }),
    );
  };
}
