import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { provideCreateSlotsStore } from '@scheduler-frontend/create-slot-modal';
import { provideActionsStore } from '@scheduler-frontend/data-access-action';
import { provideAssignmentsStore } from '@scheduler-frontend/data-access-assignment';
import { provideAwaitingSlotsStore } from '@scheduler-frontend/data-access-awaiting-slots';
import { provideLocationPayoutsStore } from '@scheduler-frontend/data-access-location-payout';
import { provideLocationsStore } from '@scheduler-frontend/data-access-locations';
import { provideSearchStore } from '@scheduler-frontend/data-access-search';
import { UsersStoreService } from '@scheduler-frontend/data-access-users';
import { oauthAuthenticationGuard, pkceAuthentication } from '@techniek-team/oauth';
import { EffectToInit } from '@scheduler-frontend/data-access-initialize';

export const ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@scheduler-frontend/login-page').then((m) => m.LoginPage),
    canActivate: [pkceAuthentication],
  },
  {
    path: '',
    loadComponent: () => import('./app/app.component').then((m) => m.AppComponent),
    canActivate: [pkceAuthentication, oauthAuthenticationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadComponent: () => import('./app/home/home.page').then((m) => m.HomePage),
        title: 'Roostertool - Home',
        providers: [
          provideSearchStore(),
          provideAwaitingSlotsStore(),
          provideActionsStore(),
          provideLocationPayoutsStore(),
        ],
        data: {
          effectsToInit: [EffectToInit.SEARCH_QUERY_MANAGEMENT],
        },
      },
      {
        path: 'zoeken',
        loadChildren: () =>
          Promise.all([
            import('@scheduler-frontend/search-overview'),
            import('@scheduler-frontend/search-results'),
          ]).then((modules) => modules.map((m) => m.ROUTES).flat(1)),
      },
      {
        path: 'locatierooster',
        title: 'Roostertool - Locatierooster',
        loadChildren: () => import('@scheduler-frontend/location-schedule').then((m) => m.ROUTES),
      },
      {
        path: 'inplannen',
        title: 'Roostertool - Inplannen',
        loadChildren: () => import('@scheduler-frontend/assign-candidate').then((m) => m.ROUTES),
      },
      {
        path: 'benaderlijst',
        title: 'Roostertool - Benaderlijst',
        loadChildren: () => import('@scheduler-frontend/page-approach-list').then((m) => m.ROUTES),
      },
      {
        path: 'benader-begeleider',
        title: 'Roostertool - Begeleider Benaderen',
        loadChildren: () =>
          import('@scheduler-frontend/page-approach-candidate').then((m) => m.ROUTES),
      },
      {
        path: 'daadwerkelijk-gewerkte-uren',
        children: [
          {
            path: 'doorgeven-begeleiders',
            loadComponent: () =>
              import(
                './app/hours-approval-overview/hours-approval-by-candidate-overview/hours-approval-by-candidate-overview.page'
              ).then((m) => m.HoursApprovalByCandidateOverviewPage),
            title: 'Roostertool - Daadwerkelijk gewerkte uren goedkeuren',
          },
          {
            path: 'doorgeven-vestigingsmanager',
            loadComponent: () =>
              import(
                './app/hours-approval-overview/hours-approval-via-scheduler-overview/hours-approval-via-scheduler-overview.page'
              ).then((m) => m.HoursApprovalViaSchedulerOverviewPage),
            title: 'Roostertool - Daadwerkelijk gewerkte uren goedkeuren',
          },
        ],
      },
      {
        path: 'uitbetaling',
        children: [
          {
            path: 'hoofdkantoor',
            loadChildren: () =>
              import('@scheduler-frontend/admin-approval-overview').then((m) => m.ROUTES),
          },
          {
            path: 'locatie',
            loadChildren: () =>
              import('@scheduler-frontend/location-approval-overview').then((m) => m.ROUTES),
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: () => {
              const userStoreService = inject(UsersStoreService);
              if (userStoreService.isAdmin()) {
                return 'hoofdkantoor';
              }
              return 'locatie';
            },
          },
        ],
      },
      {
        path: 'wachtend-op-shift',
        providers: [provideAwaitingSlotsStore(), provideCreateSlotsStore()],
        children: [
          {
            path: 'eerste-shift',
            loadComponent: () =>
              import('@scheduler-frontend/awaiting-first-slot').then(
                (m) => m.AwaitingFirstSlotPage,
              ),
          },
          {
            path: 'vervolg-shift',
            loadComponent: () =>
              import('@scheduler-frontend/awaiting-future-slot').then(
                (m) => m.AwaitingFutureSlotPage,
              ),
          },
          { path: '', pathMatch: 'full', redirectTo: 'eerste-shift' },
        ],
      },
      {
        path: 'begeleiders',
        loadChildren: () => import('@scheduler-frontend/candidates').then((m) => m.ROUTES),
      },
      {
        path: 'location-overview',
        redirectTo: 'locatierooster',
      },
      {
        path: 'agenda',
        redirectTo: 'locatierooster',
      },
      {
        path: 'hours-approval-overview',
        redirectTo: 'daadwerkelijk-gewerkte-uren',
      },
      {
        path: 'search',
        redirectTo: 'zoeken',
      },
      {
        path: 'candidate-search',
        redirectTo: 'begeleiders',
      },
      {
        path: 'dashboard',
        redirectTo: '/home',
      },
      {
        path: 'location-payout-approval-overview',
        redirectTo: 'uitbetaling/locatie',
      },
      {
        path: 'payout-approval-overview',
        redirectTo: 'uitbetaling/hoofdkantoor',
      },
      {
        path: '**',
        loadComponent: () =>
          import('@scheduler-frontend/not-found').then((m) => m.NotFoundComponent),
      },
    ],
  },
  {
    path: '**',
    canActivate: [pkceAuthentication, oauthAuthenticationGuard],
    loadComponent: () => import('@scheduler-frontend/not-found').then((m) => m.NotFoundComponent),
  },
];
