import { AssignmentTransitionMessageEnum } from '@scheduler-frontend/data-access-assignment';
import { ApplyTransitionContract } from '../contract/apply-transition.contract';

export function isSingleInfoOrSuccess(messages: ApplyTransitionContract['messages']): boolean {
  return (
    messages.length === 1 &&
    [AssignmentTransitionMessageEnum.INFO, AssignmentTransitionMessageEnum.SUCCESS].includes(
      messages[0].messageType,
    )
  );
}
