import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UsersStoreService } from '@scheduler-frontend/data-access-users';
import { environment } from '@scheduler-frontend/environments';
import { MercureClient, MercureEvent } from '@techniek-team/mercure-client';
import { isDefined } from '@techniek-team/rxjs';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MercureExportChangedEventsApi {
  protected readonly http = inject(HttpClient);

  private readonly mercureClient = inject(MercureClient);

  private readonly usersStoreService = inject(UsersStoreService);

  public exportChangeEvents(): Observable<MercureEvent<void>> {
    return this.usersStoreService.user$.pipe(
      isDefined(),
      switchMap(() =>
        this.mercureClient.request<MercureEvent<void>>(
          environment.mercure.hub,
          ['export-created', 'export-revered'],
          {
            tokenEndpoint: true,
            debug: true,
          },
        ),
      ),
    );
  }
}
