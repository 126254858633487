import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import {
  IonButton,
  IonItem,
  IonList,
  IonListHeader,
  IonSpinner,
  IonToggle,
} from '@ionic/angular/standalone';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { TtNumberInputControlComponent } from '@techniek-team/components/number-input';

@Component({
  selector: 'app-self-assign-form',
  templateUrl: './self-assign-form.component.html',
  styleUrls: ['./self-assign-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    IonItem,
    IonToggle,
    IonList,
    IonListHeader,
    IonSpinner,
    TtNumberInputControlComponent,
    FaIconComponent,
    IonButton,
    MatTooltip,
  ],
})
export class SelfAssignFormComponent {
  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  protected readonly faFloppyDisk = faFloppyDisk;

  protected readonly selfAssignableToggle = computed(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    return new FormControl<boolean>(
      { value: assignment?.selfAssignable ?? false, disabled: true },
      { nonNullable: true },
    );
  });

  protected readonly selfAssignableWhenNewToggle = computed(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    return new FormControl<boolean>(
      { value: assignment?.allowSelfAssignWhenNew ?? false, disabled: true },
      { nonNullable: true },
    );
  });

  protected readonly selfAssignParametersForm = computed(() => {
    const assignment = this.activeAssignmentStoreService.assignment();
    const grade =
      assignment && assignment.minimalGradeSelfAssign ? +assignment.minimalGradeSelfAssign : 7.5;

    return new FormGroup({
      minimalGradeSelfAssign: new FormControl<number | null>({ value: grade, disabled: true }, [
        Validators.min(0),
        Validators.max(10),
      ]),
      maxTravelDistanceSelfAssign: new FormControl<number | null>(
        {
          value: assignment?.maxTravelDistanceSelfAssign ?? null,
          disabled: true,
        },
        [Validators.min(0), Validators.max(350)],
      ),
    });
  });

  protected editSelfAssignable(): void {
    const isSelfAssignable = this.selfAssignableToggle().getRawValue();
    const isSelfAssignableWhenNew = this.selfAssignableWhenNewToggle().getRawValue();

    this.activeAssignmentStoreService.setActiveAssignmentSelfAssignmentSettings(
      isSelfAssignable,
      isSelfAssignableWhenNew,
    );
  }

  protected readonly canEditSelfAssignable = effect(() => {
    const granted = this.activeAssignmentsPermissionsStoreService.canEditSelfAssignable();
    if (granted) {
      this.selfAssignableToggle().enable({ emitEvent: false });
      this.selfAssignableWhenNewToggle().enable({ emitEvent: false });
      this.selfAssignParametersForm().enable({ emitEvent: false });
    } else {
      this.selfAssignableToggle().disable({ emitEvent: false });
      this.selfAssignableWhenNewToggle().disable({ emitEvent: false });
      this.selfAssignParametersForm().disable({ emitEvent: false });
    }
  });

  protected readonly disableMinimalGrade = effect(() => {
    const businessService = this.activeAssignmentStoreService.assignmentBusinessService();
    if (!businessService?.hasAssignmentReviews) {
      this.selfAssignParametersForm().controls.minimalGradeSelfAssign.disable();
    }
  });

  protected readonly isSelfAssignableChange = toSignal(this.selfAssignableToggle().valueChanges);

  protected readonly disableFormOnSelfAssignableChange = effect(() => {
    this.isSelfAssignableChange();

    if (this.selfAssignableToggle().value) {
      this.selfAssignableWhenNewToggle().enable({ emitEvent: false });
      this.selfAssignParametersForm().enable({ emitEvent: false });
    } else {
      this.selfAssignableWhenNewToggle().disable({ emitEvent: false });
      this.selfAssignParametersForm().disable({ emitEvent: false });
    }
  });

  protected submitSelfAssignParameters(): void {
    if (this.selfAssignParametersForm().invalid) {
      return;
    }

    const grade = this.selfAssignParametersForm().getRawValue().minimalGradeSelfAssign;

    this.activeAssignmentStoreService.setActiveAssignmentSelfAssignmentSettings(
      this.selfAssignableToggle().getRawValue(),
      this.selfAssignableWhenNewToggle().getRawValue(),
      grade ? grade.toFixed(2) : null,
      this.selfAssignParametersForm().getRawValue().maxTravelDistanceSelfAssign,
    );
  }
}
