import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { initEffectActions } from '@scheduler-frontend/data-access-initialize';
import { locationsActions, LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { schedulesActions, SchedulesSelectors } from '@scheduler-frontend/data-access-schedules';
import { schedulingViewActions } from '@scheduler-frontend/data-access-scheduling';
import { isDefined } from '@techniek-team/rxjs';
import { NgrxAction } from '@techniek-team/tt-ngrx';
import { isEqual } from 'lodash-es';
import { exhaustMap, Observable, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';
import { SearchSelectors } from '../selector/search.selectors';

@Injectable()
export class LocationScheduleEffect implements OnRunEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public readonly setCurrentLocationAsSearchFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.selectLocation, searchActions.initLocationSchedule),
      concatLatestFrom(() => {
        return [this.store.pipe(select(LocationsSelectors.currentLocationId), isDefined())];
      }),
      map(([_, locationId]) => {
        let actionBody: NgrxAction<typeof searchActions.appendToSearchHash> = {
          isUserInitiated: false,
          setAsCurrentSearch: true,
          filters: {},
        };
        if (locationId) {
          actionBody.filters = { [LocationsSelectors.locationsFilterGroupKey]: locationId };
        }

        return searchActions.appendToSearchHash(actionBody);
      }),
    ),
  );

  public readonly setScheduleAsSearchFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulesActions.setSelectedSchedule), // Set the action to be listened
      map((action) => {
        const actionBody: NgrxAction<typeof searchActions.appendToSearchHash> = {
          isUserInitiated: false,
          setAsCurrentSearch: true,
          filters: {},
        };
        if (action.schedule) {
          actionBody.filters = { 'slot.schedule': action.schedule };
        } else {
          actionBody.removeKeys = ['slot.schedule'];
        }
        return searchActions.appendToSearchHash(actionBody);
      }),
    ),
  );

  public readonly setSelectedLocationFiltersAsSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulesActions.selectedProductTypeAtLocation,
        schedulesActions.deselectedProductTypeAtLocation,
        schedulingViewActions.setSchedulingViewState,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulesSelectors.selectedProductTypesAtLocation)),
        this.store.pipe(select(SearchSelectors.currentSearch)),
      ]),
      map(([_, selectedProductType, search]) => {
        const key = ProductTypesSelectors.productTypesFilterGroupKey;
        if (selectedProductType.length > 0) {
          if (search && isEqual(search.metaData?.filters?.[key], selectedProductType)) {
            return searchActions.noSearchChange();
          }
          return searchActions.appendToSearchHash({
            filters: { [key]: [...selectedProductType] },
            isUserInitiated: false,
            setAsCurrentSearch: true,
          });
        }

        return searchActions.removeFromSearchHash({
          keys: [key],
          isUserInitiated: false,
          setAsCurrentSearch: true,
        });
      }),
    ),
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(initEffectActions.startCreateSearchQueryForLocationSchedule),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(
            this.actions$.pipe(ofType(initEffectActions.stopCreateSearchQueryForLocationSchedule)),
          ),
        ),
      ),
    );
  }
}
