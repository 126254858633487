import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ApproachContract,
  ApproachEventType,
  ApproachMediumEnum,
} from '@scheduler-frontend/approach-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicateApproachApi {
  private readonly httpClient = inject(HttpClient);

  public execute(
    approach: string,
    approachMediums: ApproachMediumEnum[],
    hasAnsweredPhone: boolean,
    slots?: string[], // IRI,
  ): Observable<ApproachContract> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches/${approach}/communicate`;

    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/merge-patch+json',
    );

    return this.httpClient.patch<ApproachContract>(
      url,
      {
        eventTypes: this.getEventTypes(approachMediums, hasAnsweredPhone),
        slots: slots ?? [],
      },
      { headers: headers },
    );
  }

  private getEventTypes(
    approachMediums: ApproachMediumEnum[],
    hasAnsweredPhone: boolean,
  ): ApproachEventType[] {
    const eventTypes: ApproachEventType[] = [];

    for (let approachMedium of approachMediums) {
      switch (approachMedium) {
        case ApproachMediumEnum.WHATSAPP:
          eventTypes.push(ApproachEventType.WHATSAPP_SENT);
          break;
        case ApproachMediumEnum.PHONE:
          if (hasAnsweredPhone) {
            eventTypes.push(ApproachEventType.PHONE_ANSWERED);
            break;
          }
          eventTypes.push(ApproachEventType.PHONE_UNANSWERED);
          break;
      }
    }

    return eventTypes;
  }
}
