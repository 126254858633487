import { Type } from '@angular/core';
import { UserServiceInterface } from '@techniek-team/common';
import { PermissionUserInterface } from './contracts/permission-user.interface';

/**
 * This file contains a few mocks which makes the testing for {@see BasePermission}
 * classes and other code a bit easier.
 *
 * This mocks are automatically made available when using the {@see TtPermissionTestingModule}.
 */

export class MockUser implements PermissionUserInterface {
  constructor(
    public email: string,
    public roles: string[],
  ) {}
}

export const USER_MOCK: MockUser = new MockUser('vc@mock.nl', [
  'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
]);

export const ADMIN_MOCK: MockUser = new MockUser('admin@mock.nl', [
  'ROLE_USER',
  'ROLE_SKOLEO_TT_PLANNER_ADMIN',
]);

/**
 * The mock contains a public accessible {@see UserServiceMock.user} property which
 * can be used to quickly change the user which is used is for the permission classes.
 */
export class UserServiceMock implements UserServiceInterface<MockUser> {
  public user: MockUser = USER_MOCK;

  public getRoles(): Promise<string[]> {
    return Promise.resolve(this.user.roles);
  }

  public getUser(): Promise<MockUser> {
    return Promise.resolve(this.user);
  }
}

/**
 * Other than the normal {@see TtPermissionConfig } this mock automatically sets
 * the {@see UserServiceMock } as the used userService.
 */
export class TtPermissionConfigMock {
  public userService?: Type<UserServiceInterface<MockUser>> = UserServiceMock;

  public roleHierarchy?: Record<string, string | string[]> = {};
}
