import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightFromBracket, faVirus } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonNote,
  IonPopover,
  IonSpinner,
  IonToggle,
  ModalController,
} from '@ionic/angular/standalone';
import {
  AssignmentAssignmentHasSlotContract,
  AssignmentHasSlotPurposeEnum,
  AssignmentStateEnum,
  getRealTimePeriod,
} from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentsPermissionsStoreService,
  ActiveAssignmentStoreService,
  AssignmentsStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { RolesStoreService } from '@scheduler-frontend/data-access-roles';
import { timeRangeValidator } from '@techniek-team/common';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { TtNumberInputControlComponent } from '@techniek-team/components/number-input';
import { TtTimeInputControlComponent } from '@techniek-team/components/time-input';
import { jsonLdSelectId, JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';
import { differenceInMinutes, formatISO } from 'date-fns';
import { NgxControlError } from 'ngxtension/control-error';
import { map } from 'rxjs/operators';

export type AssignmentHasSlotRowFormValue = FormGroup<AssignmentHasSlotRowForm>['value'];

interface AssignmentHasSlotRowForm {
  breakTime: FormControl<number>;

  actualTimePeriod: FormGroup<{
    start: FormControl<Date>;
    end: FormControl<Date>;
  }>;
}

@Component({
  selector: 'app-assignment-has-slot-item',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TtNumberInputControlComponent,
    TtTimeInputControlComponent,
    IonButton,
    IonSpinner,
    IonToggle,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
    JsonLdSelectIdPipe,
    IonNote,
    NgxControlError,
  ],
  templateUrl: './assignment-has-slot-item.component.html',
  styleUrls: ['./assignment-has-slot-item.component.scss'],
})
export class AssignmentHasSlotItemComponent {
  protected readonly rolesStoreService = inject(RolesStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  private readonly modalController = inject(ModalController);

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentStoreService = inject(ActiveAssignmentStoreService);

  protected readonly faCheck = faCheck;

  protected readonly faEllipsisVertical = faEllipsisVertical;

  protected readonly faTrashCan = faTrashCan;

  protected readonly faArrowRightFromBracket = faArrowRightFromBracket;

  protected readonly faVirus = faVirus;

  protected readonly AssignmentHasSlotPurposeEnum = AssignmentHasSlotPurposeEnum;

  protected readonly AssignmentStateEnum = AssignmentStateEnum;

  public assignmentHasSlot = input.required<AssignmentAssignmentHasSlotContract>();

  public targetRole = computed(() => {
    return this.rolesStoreService.roleEntities()[
      jsonLdSelectId(this.assignmentHasSlot().slot.role)
    ];
  });

  protected readonly form = new FormGroup(
    {
      breakTime: new FormControl<number>(
        { value: 0, disabled: true },
        {
          nonNullable: true,
          validators: [Validators.required, Validators.min(0)],
        },
      ),
      actualTimePeriod: new FormGroup(
        {
          start: new FormControl<Date>(
            { value: new Date(), disabled: true },
            {
              nonNullable: true,
              validators: [Validators.required],
            },
          ),
          end: new FormControl<Date>(
            { value: new Date(), disabled: true },
            {
              nonNullable: true,
              validators: [Validators.required],
            },
          ),
        },
        [timeRangeValidator({ startTimeName: 'start', endTimeName: 'end' })],
      ),
    },
    { validators: this.validateBreakTime },
  );

  public setForm = effect(
    () => {
      const assignmentState = this.activeAssignmentStoreService.assignment()?.state;
      const formDisabledForStates = [
        AssignmentStateEnum.UNASSIGNED,
        AssignmentStateEnum.WAITING_FOR_CONFIRMATION,
      ];
      const isDisabled = assignmentState ? formDisabledForStates.includes(assignmentState) : false;

      // Enable or disable the entire form based on assignmentState
      if (isDisabled) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }

      // Handle the breakTime control logic independently based on targetRole
      const targetRole = this.targetRole();
      if (!isDisabled && targetRole && targetRole.allowBreakTime) {
        this.form.controls.breakTime.enable();
      } else {
        this.form.controls.breakTime.disable();
      }

      // Ensure the form's values are reset accordingly.
      const hasSlot = this.assignmentHasSlot();
      this.form.reset(
        {
          breakTime: hasSlot.breakTime ?? 0,
          actualTimePeriod: {
            start: new Date(getRealTimePeriod(hasSlot).start),
            end: new Date(getRealTimePeriod(hasSlot).end),
          },
        },
        { emitEvent: true },
      );
    },
    { allowSignalWrites: true },
  );

  protected canRemoveSlot = toSignal(
    toObservable(this.assignmentHasSlot).pipe(
      map((hasSlot) =>
        this.activeAssignmentsPermissionsStoreService.canRemoveSlotFromAssignment$(hasSlot),
      ),
    ),
  );

  protected changeIsBillable(): void {
    const lesson = this.assignmentHasSlot().slot.lesson;
    this.activeAssignmentStoreService.setIsBillable(
      jsonLdSelectId(this.assignmentHasSlot()),
      !lesson.isBillable,
    );
  }

  protected unassignSlot(): void {
    this.assignmentsStoreService.unassignSlotFromActiveAssigment(this.assignmentHasSlot());
  }

  protected async removeSlot(): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      componentProps: {
        title: 'Shift openstellen',
        message: 'Deze shift zal worden ontkoppeld van deze opdracht.',
      },
    });
    await confirmModal.present();
    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      this.assignmentsStoreService.removeSlotFormAssignment(
        jsonLdSelectId(this.assignmentHasSlot().slot),
      );
    }
  }

  protected markAsPresent(): void {
    this.assignmentsStoreService.markAsPresent(jsonLdSelectId(this.assignmentHasSlot()));
  }

  protected confirmTime(): void {
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    const formValue = this.form.getRawValue();

    this.activeAssignmentStoreService.updateActualWorkingTimes(
      jsonLdSelectId(this.assignmentHasSlot()),
      {
        start: formatISO(formValue.actualTimePeriod.start),
        end: formatISO(formValue.actualTimePeriod.end),
        inclusiveStart: true,
        inclusiveEnd: false,
      },
      this.targetRole()?.allowBreakTime ? +formValue.breakTime : undefined,
    );
  }

  /**
   * Make sure the length of the break is not longer than the length of the slot.
   */
  private validateBreakTime(group: AbstractControl): ValidationErrors | null {
    if (!group || !(group instanceof FormGroup)) {
      return null;
    }

    const formValue: AssignmentHasSlotRowFormValue = group.getRawValue();
    const start = formValue.actualTimePeriod?.start;
    const end = formValue.actualTimePeriod?.end;
    const breakTimeMinutes = formValue.breakTime;

    if (!start || !end || !breakTimeMinutes) {
      return null;
    }

    const shiftLengthMinutes = differenceInMinutes(end, start);

    if (shiftLengthMinutes > breakTimeMinutes) {
      return null;
    }

    return { breakIsLongerThanWorkingTimes: { value: breakTimeMinutes } };
  }
}
