import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  assignmentsActions,
  AssignmentsSelectors,
} from '@scheduler-frontend/data-access-assignment';
import { slotsActions } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { map } from 'rxjs';
import { schedulingActions } from '../actions/scheduling.actions';

@Injectable()
export class SlotSelectionEffect {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  public addAssignmentToStore = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingActions.retrieveSlotsWithAssignmentForDeselectionSuccess,
        schedulingActions.retrieveSlotsWithAssignmentForSelectionSuccess,
      ),
      concatLatestFrom(() => [
        this.store.select(AssignmentsSelectors.assignmentEntities).pipe(isDefined()),
      ]),
      map(([action, assignments]) =>
        assignmentsActions.addAssignments({
          assignments: action.assignments.filter(
            (assignment) => !assignments[jsonLdSelectId(assignment)],
          ),
        }),
      ),
    ),
  );

  public loadRetrievedSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.retrieveSlotsWithAssignmentForSelectionSuccess),
      map((action) => slotsActions.loadSlots({ slotIds: action.slots, setAsSlotList: false })),
    ),
  );

  public clearSelectedSlotsEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.clearAllSelections),
      map(schedulingActions.clearSelectedSlots),
    ),
  );
}
