import { JestFn } from '@techniek-team/jest-utils';
import { VOID } from '@techniek-team/rxjs';
import { Observable, of } from 'rxjs';
import { IMutableContext as UnleashMutableContext } from 'unleash-proxy-client';

export class TtFeatureFlagsServiceMock {
  public onInitialized: Observable<unknown> = of(undefined);

  public onUpdate: Observable<unknown> = of(undefined);

  public onError: Observable<Error> = VOID as unknown as Observable<Error>;

  public start: JestFn<void> = jest.fn();

  public stop: JestFn<void> = jest.fn();

  public setFlag: JestFn<void> = jest.fn((_name: string, _value: boolean) => {
    // okay
  });

  public removeFlag: JestFn<void> = jest.fn((_name: string) => {
    // okay
  });

  public updateContext: JestFn<Promise<void>> = jest.fn((_contextObj: UnleashMutableContext) => {
    return Promise.resolve();
  });

  public isEnabled: JestFn<boolean> = jest.fn((_feature: string) => {
    return true;
  });
}
