import { Dictionary } from '@ngrx/entity/src/models';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { schedulingActions } from '../+state/actions/scheduling.actions';

export function findAllAssignments(
  action:
    | ReturnType<typeof schedulingActions.selectSlots>
    | ReturnType<typeof schedulingActions.deselectSlots>,
  slots: Dictionary<SlotDetailedContract | SlotContract>,
): string[] {
  const assignments = action.items.map((id) => {
    const slot = slots[id];
    if (slot && 'assignmentHasSlot' in slot) {
      return jsonLdSelectId(slot.assignmentHasSlot?.assignment?.['@id']);
    }
    return undefined;
  });
  const set = new Set(assignments);
  set.delete(undefined);

  return [...set] as string[];
}
