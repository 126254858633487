import { PlainRangeInterface } from '@techniek-team/tt-range';

export interface SearchContract {
  hash: string;
  metaData?: SearchMetaDataContract;
}

export interface SearchDetailedContract {
  hash: string;
  metaData?: SearchMetaDataContract;
  slots?: string[];
}

export interface SearchMetaDataContract {
  query: string;
  filters: Filters;
}

export interface Filters {
  [param: string]:
    | PlainRangeInterface<string | number>
    | string
    | number
    | readonly (string | number)[];
}

export function isSearchDetailedContract(
  item: SearchContract | SearchDetailedContract,
): item is SearchDetailedContract {
  return 'slots' in item;
}
