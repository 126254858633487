import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, from, Observable, switchMap } from 'rxjs';
import { EditSlotModalComponent } from '../edit-slot-modal/edit-slot-modal.component';
import { editSlotActions } from './edit-slot.actions';

@Injectable()
export class OpenEditSlotEffects {
  private readonly actions$ = inject(Actions);

  private readonly modalController = inject(ModalController);

  public readonly openEditSlotModal: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editSlotActions.openEditSlotModal),
        exhaustMap(() => {
          return from(
            this.modalController.create({
              component: EditSlotModalComponent,
              cssClass: ['create-slot-modal'],
            }),
          ).pipe(
            switchMap((modal) => modal.present().then(() => modal)),
            switchMap((modal) => modal.onWillDismiss()),
          );
        }),
      ),
    { dispatch: false },
  );
}
