import { NgModule } from '@angular/core';
import { TtNumberInputControlComponent } from './tt-number-input-control.component';

/**
 * @deprecated use standalone component
 */
@NgModule({
  imports: [TtNumberInputControlComponent],
  exports: [TtNumberInputControlComponent],
})
export class TtNumberInputModule {}
