<div class="table">
  <div class="header status"></div>
  <div class="header date">Datum</div>
  <div class="header time">Geplande tijd</div>
  <div class="header break-input">Pauze (in minuten)</div>
  <div class="header time-input">Werkelijke tijd</div>
  <div class="header role">Rol</div>
  <div class="header saldo">Saldo</div>
  <div class="header actions"></div>

  @for (
    assignmentHasSlot of activeAssignmentStoreService.assignmentHasSlots() ?? [];
    track assignmentHasSlot;
    let index = $index
  ) {
    <app-assignment-has-slot-item
      [style.grid-row]="index + 3"
      [assignmentHasSlot]="assignmentHasSlot"
    ></app-assignment-has-slot-item>
  }
</div>
