import { PlainRangeInterface } from '@techniek-team/tt-range';
import { addMonths, formatISO, subWeeks } from 'date-fns';
import { FormatISOOptions } from 'date-fns/formatISO';
import { Filters, SearchContract, SearchMetaDataContract } from '../../../contract/search.contract';
import { UserSearchContract } from '../../../contract/user-search.contract';

export function adjustFiltersToDateRangeToView(
  currentSearch: UserSearchContract | SearchContract,
  timeRangeInView: PlainRangeInterface<string | number> | 'infinite',
): Filters {
  const search = ('search' in currentSearch ? currentSearch.search : currentSearch) as {
    metaData: SearchMetaDataContract;
  };
  const filters = search.metaData.filters;
  const lessonDateFilter = filters['lesson.date'] as PlainRangeInterface<string>;
  const formatIsoObject: FormatISOOptions = { representation: 'date' };

  if (timeRangeInView === 'infinite') {
    return {
      ...filters,
      'lesson.date': {
        start: lessonDateFilter?.start || formatISO(subWeeks(new Date(), 2), formatIsoObject),
        end: lessonDateFilter?.end || formatISO(addMonths(new Date(), 2), formatIsoObject),
        inclusiveStart: true,
        inclusiveEnd: false,
      },
    };
  }

  return {
    ...filters,
    'lesson.date': {
      ...timeRangeInView,
      start: formatISO(timeRangeInView.start, formatIsoObject),
      end: formatISO(timeRangeInView.end, formatIsoObject),
    },
  };
}
