import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchContract } from '../../contract/search.contract';
import { convertGetHashResponseV1ToV3 } from './convert-get-user-searches-response.function';
import { GetHashResponseV1 } from './search.response';

@Injectable({
  providedIn: 'root',
})
export class LoadSearchApi {
  protected readonly http = inject(HttpClient);

  public execute(hash: string): Observable<SearchContract> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/searches/${hash}`;

    return this.http
      .get<GetHashResponseV1>(url)
      .pipe(map((response) => convertGetHashResponseV1ToV3(response)));
  }
}
