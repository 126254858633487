import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApproachContract } from '@scheduler-frontend/approach-contracts';
import { LevelEnum } from '@scheduler-frontend/enums';
import { environment } from '@scheduler-frontend/environments';

@Injectable({
  providedIn: 'root',
})
export class CreateApproachApi {
  private readonly httpClient = inject(HttpClient);

  public execute(
    candidates: string[],
    showOnApproachList: boolean,
    slots?: string[],
    filters?: {
      businessServices?: string[];
      roles?: string[];
      levels?: LevelEnum[];
      subjects?: string[];
    },
  ) {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches`;
    return this.httpClient.post<ApproachContract[]>(url, {
      candidates: candidates.map(
        (candidate) => `${environment.scheduler.iri}/v3/candidates/${candidate}`,
      ),
      slots: slots,
      isCandidateBased: showOnApproachList,
      ...filters,
    });
  }
}
