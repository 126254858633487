import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { catchError, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { userSearchActions } from '../action/user-search.actions';
import { RecentSearchesApi } from '../api/recent-searches.api';

@Injectable()
export class SearchRecentEffects {
  private readonly actions$ = inject(Actions);

  private readonly recentSearchApi = inject(RecentSearchesApi);

  public readonly loadRecentSearches = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.loadRecentSearches),
      switchMap(() =>
        this.recentSearchApi.execute().pipe(
          map((searches) => userSearchActions.loadRecentSearchesSuccess({ searches: searches })),
          catchError((error) => of(userSearchActions.loadRecentSearchesFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly loadRecentSearchesFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(userSearchActions.loadRecentSearchesFailure, {
          message: 'Er is iets misgegaan bij het ophalen van recente zoekopdrachten.',
        }),
      ),
    { dispatch: false },
  );
}
