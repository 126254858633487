import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import {
  EffectToInit,
  initEffectActions,
  initializeActions,
  InitializeSelectors,
  SchedulingViewQueryParams,
} from '@scheduler-frontend/data-access-initialize';
import { schedulingViewActions } from '@scheduler-frontend/data-access-scheduling';
import { environment } from '@scheduler-frontend/environments';
import { RoutesView } from '@scheduler-frontend/scheduling-common';
import { consoleInDev } from '@techniek-team/common';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';
import { userSearchActions } from '../action/user-search.actions';

@Injectable()
export class SearchInitEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public ngrxOnInitEffects(): Action {
    return initializeActions.triggerInit({
      processes: [EffectToInit.SEARCH_QUERY_LOCATION_STATISTICS],
    });
  }

  public readonly setCurrentSearchOnColdBoot = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [
        this.store.select(InitializeSelectors.currentRoute),
        this.store.select(InitializeSelectors.selectRouteNestedParams),
        this.store.select(InitializeSelectors.selectRouteNestedQueryParams),
      ]),
      filter(([_action, route, params, queryParams]) => !route),
      filter(([_action, route, params, queryParams]) => !!params['id']),
      map(([_action, route, params, queryParams]) => {
        consoleInDev(environment.debug).info(
          `%cSetup cold boot search query`,
          `background: #34fa31; color: #6b1fa3; font-weight: bold;`,
        );
        if (queryParams['userInitiated'] !== 'false') {
          return userSearchActions.setColdCurrentUserSearch({ searchId: params['id'] });
        }
        return searchActions.startColdSystemSearch({ searchId: params['id'] });
      }),
    );
  });

  public readonly setCurrentSearchOnBoot = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [
        this.store.select(InitializeSelectors.currentRoute),
        this.store.select(InitializeSelectors.selectRouteNestedParams),
        this.store.select(InitializeSelectors.selectRouteNestedQueryParams),
      ]),
      filter(([_action, route, params, queryParams]) => !!route),
      filter(([_action, route, params, queryParams]) => !!params['id']),
      map(([_action, route, params, queryParams]) => {
        consoleInDev(environment.debug).info(
          `%cSetup normal search query`,
          `background: #34fa31; color: #6b1fa3; font-weight: bold;`,
        );
        if (queryParams['userInitiated'] !== 'false') {
          return userSearchActions.setCurrentUserSearch({ searchId: params['id'] });
        }
        return searchActions.setCurrentSystemSearch({ searchId: params['id'] });
      }),
    );
  });

  public readonly initSchedulingBySearch = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.startLoadingSlotsBasedOnSystemSearch),
      map(() => searchActions.initLoadingSlotsBasedOnSystemSearch()),
    ),
  );

  public readonly stopInitSchedulingBySearch = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.stopLoadingSlotsBasedOnSystemSearch),
      map(() => searchActions.stopInitLoadingSlotsBasedOnSystemSearch()),
    ),
  );

  public readonly initLocationSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType(initEffectActions.startCreateSearchQueryForLocationSchedule),
      map(() => searchActions.initLocationSchedule()),
    ),
  );
}
