import { ModuleWithProviders, NgModule } from '@angular/core';
import { PermissionUserInterface } from './contracts/permission-user.interface';
import { IsGrantedDirectiveMock } from './is-granted/is-granted.directive.mock';
import { PermissionService } from './service/permission.service';
import { PermissionServiceMock } from './service/permission.service.mock';
import { TtPermissionConfig } from './tt-permission.config';
import { TtPermissionConfigMock, UserServiceMock } from './tt-permission.mock';

/**
 * @deprecated
 */
@NgModule({
  imports: [IsGrantedDirectiveMock],
  exports: [IsGrantedDirectiveMock],
})
export class TtPermissionTestingModule {
  public static forRoot<T extends PermissionUserInterface>(
    options: TtPermissionConfigMock,
  ): ModuleWithProviders<TtPermissionTestingModule> {
    options = { ...new TtPermissionConfigMock(), ...options };

    return {
      ngModule: TtPermissionTestingModule,
      providers: [
        UserServiceMock,
        { provide: TtPermissionConfig, useValue: options },
        { provide: PermissionService, useClass: PermissionServiceMock },
      ],
    };
  }
}
