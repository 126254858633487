export enum EffectToInit {
  /**
   * creating userSearch into a system search
   * update a userSearch or system search.
   */
  SEARCH_QUERY_MANAGEMENT = 'SEARCH_QUERY_MANAGEMENT',

  /**
   * showing favorite and recent search to user.
   * marking a search as favorite.
   */
  USER_SEARCH_FAVORITES_AND_RECENT_MANAGEMENT = 'USER_SEARCH_FAVORITES_AND_RECENT_MANAGEMENT',

  /**
   * load slot based on the system search
   */
  LOADING_SLOTS_BASED_ON_SYSTEM_SEARCH = 'LOADING_SLOTS_BASED_ON_SYSTEM_SEARCH',

  /**
   * selecting slots
   * managing the views.
   * managing timeRange in view
   */
  SCHEDULE_MANAGEMENT = 'SCHEDULE_MANAGEMENT',

  /**
   * list of locations and statistics about it based on search query.
   */
  SEARCH_QUERY_LOCATION_STATISTICS = 'SEARCH_QUERY_LOCATION_STATISTICS',

  /**
   * loading a list of matching candidates
   * assigning candidate
   */
  CANDIDATE_ASSIGN = 'CANDIDATE_ASSIGN',

  /**
   * Mode in which the slot checkboxes are available and we can select slots.
   * This mode enables the user to minipulate the list of selected slots but isn't
   * about the list. for that see {@see SELECTED_SLOTS_MANAGEMENT }
   */
  SELECTING_SLOTS = 'SELECTING_SLOTS',

  /**
   * This progress keeps the list of selected slot which will be cleared when the progress
   * is being stopped
   */
  SELECTED_SLOTS_MANAGEMENT = 'SELECTED_SLOTS_MANAGEMENT',

  /**
   * Create system searches based on the selected location and product(s).
   */
  CREATE_SEARCH_QUERY_FOR_LOCATION_SCHEDULE = 'CREATE_SEARCH_QUERY_FOR_LOCATION_SCHEDULE',

  /**
   * Products for the available schedules at the location schedule overview.
   */
  PRODUCT_SCHEDULE_MANAGEMENT = 'PRODUCT_SCHEDULE_MANAGEMENT',

  /**
   * This is to process in which we load detailed information about a selected candidate.
   */
  CANDIDATE_DETAILS_MANAGEMENT = 'CANDIDATE_DETAILS_MANAGEMENT',

  /**
   * In this process we load the lists of candidates to appoarch and the to-do, awaiting and done
   * sub lists
   */
  CANDIDATE_TO_APPROACH_MANAGEMENT = 'CANDIDATE_TO_APPROACH_MANAGEMENT',

  /**
   * In this progess we manage the state of the user that's bussy selecting slots to approach the targeted
   * candidate with
   */
  CREATING_APPROACH_MANAGEMENT = 'CREATING_APPROACH_MANAGEMENT',

  /**
   * In this process we manage the state of a user that got response from the candidate and is assigning the
   * candidate to a assignment, or acknowlegde that the candidate can't or wont do assignments.
   */
  HANDLING_APPROACH_RESPONSE_MANAGEMENT = 'HANDLING_APPROACH_RESPONSE_MANAGEMENT',

  /**
   * This process gathers information to supply the user with alternative slot to approach the candidate
   * with when the original assignment is already been filled
   */
  SHOW_ALTERNATIVE_SHIFTS = 'SHOW_ALTERNATIVE_SHIFTS',

  /**
   * This enables assignment card to show inline assign buttons
   */
  SHOW_INLINE_ASSIGN_BUTTONS = 'SHOW_INLINE_ASSIGN_BUTTONS',
}
