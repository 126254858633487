import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlotInAssignmentApi {
  protected readonly httpClient = inject(HttpClient);

  /**
   * Retrieves a detailed version of the Assignment resource for the given parameter.
   */
  public execute(assignment: string): Observable<AssignmentDetailedContract> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/assignments/${assignment}`;
    return this.httpClient.get<AssignmentDetailedContract>(url);
  }
}
